import React, { useRef } from "react"
import {
  SvgDashboard,
  SvgLearn,
  SvgVideoOutline,
  SvgChat,
  SvgListCheck,
  SvgUsers,
  SvgArrowRight,
} from "../icons"
import { useNavigate } from "react-router-dom"

const IINProgram: React.FC<{
  pathname: string
  jotformRef: React.RefObject<HTMLDivElement>
}> = ({ pathname, jotformRef }) => {
  const navigate = useNavigate()

  const handleCreateAccount = () => {
    navigate("/iin-registration", { state: { from: pathname } })
  }

  const scrollToJotform = () => {
    if (jotformRef?.current) {
      jotformRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const features = [
    {
      title: "All-in-one Online Business",
      description:
        "Three-month access to the Zoee platform with guidance on how to set up an easy-to-manage, streamlined, and more profitable practice",
      icon: SvgDashboard,
    },
    {
      title: "A Comprehensive Workbook",
      description:
        "Weekly workbook chapters with essential exercises and targeted tips, such as how to use AI in your business, provide a practical step-by-step business development strategy",
      icon: SvgLearn,
    },
    {
      title: "3 Live Webinars",
      description:
        "Interactive webinars with expert coaches to dive deeper into business strategy, explore the ins and outs of entrepreneurship, and get coaching to help you move forward",
      icon: SvgVideoOutline,
    },
    {
      title: "Accountability Support",
      description:
        "Small groups provide a weekly space to connect with peers to share ideas, exchange feedback, and help each other stay on track",
      icon: SvgChat,
    },
    {
      title: "Weekly Challenges",
      description:
        "High-impact, weekly action steps intentionally curated to challenge you in a way that’s manageable, fun, and engineered to drive results in your business",
      icon: SvgListCheck,
    },
    {
      title: "Coach Connections",
      description:
        "Dedicated discussion forums to connect with peers and receive support from IIN coaches and Zoee business experts​",
      icon: SvgUsers,
    },
  ]

  return (
    <div className="py-[56px] md:py-[96px] flex flex-col items-center justify-center overflow-hidden md:px-[144px] px-4 sm:px-8 max-w-screen-xl mx-auto">
      {/* Title Section */}
      <div className="flex flex-col items-start gap-4 w-full max-w-6xl text-left">
        <h2 className="text-black text-[48px] md:text-5xl font-bold leading-snug">
          What You’ll Get
        </h2>

        <p
          className="text-black font-normal w-full"
          style={{
            fontSize: "22px",
            lineHeight: "26.4px",
            textAlign: "left",
          }}
        >
          This action-oriented program provides the practical guidance, tools,
          and accountability to help you build a thriving coaching practice with
          minimal overhead so you can focus your time and energy supporting
          clients and doing what you love. You’ll receive...
        </p>
      </div>

      {/* Program Features */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-6xl">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex flex-col items-start gap-4 p-4 rounded-lg"
          >
            {/* Icon */}
            <div className="p-4 bg-[#7190ff] rounded-full flex items-center justify-center">
              <feature.icon className="w-6 h-6 text-white" />
            </div>
            {/* Feature Title */}
            <h3 className="text-black text-[22px] leading-[26.4px] font-bold">
              {feature.title}
            </h3>
            <p className="text-black text-[16px] leading-[24px] font-normal text-left">
              {feature.description}
            </p>
          </div>
        ))}
      </div>

      {/* Call to Action */}
      <div className="mt-8">
        {/* Register Button */}
        {/* <button
          className="px-6 py-3 bg-[#4750f5] text-white text-[16px] font-bold rounded-lg flex items-center gap-2"
          onClick={handleCreateAccount}
        >
          REGISTER NOW
          <SvgArrowRight className="w-5 h-5" />
        </button> */}
        {/* Waitlist Button */}
        <button
          className="px-6 py-3 bg-[#4750f5] text-white text-[16px] font-bold rounded-lg flex items-center gap-2"
          onClick={scrollToJotform}
        >
          JOIN WAITLIST
          <SvgArrowRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  )
}

export default IINProgram
