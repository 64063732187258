/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from "react"
import "react-phone-input-2/lib/style.css"
import DragDropArea from "../../../../components/DragDropArea"
import Loader from "../../../../components/ui/loader"
import PhoneInput from "react-phone-input-2"
import Select from "react-select"
import TimezoneSelect, { allTimezones } from "react-timezone-select"
import useLoading from "../../../../hooks/useLoading"
import useScrollToTop from "../../../../hooks/useScrollToTop"
import VerifyPhonePopup from "../../../../components/authentication/VerifyPhonePopup"
import { Button } from "../../../../components/ui/button"
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext"
import { doesEmailExist } from "../../../../components/auth/register-form/registration-form"
import { regions } from "../../../../objects/regionObjects"
import { StreamChat } from "stream-chat"
import {
  SvgCheckCircle,
  SvgClose,
  SvgTrash,
  SvgUploadImage,
  SvgVerifyEmail,
  SvgVerifyPhone,
} from "../../../../components/icons/"
import { useLocation } from "react-router-dom"
import { validateFirstAndLastName } from "../../../../components/auth/register-form/name-form"
import { VerifyEmail } from "../../../../api/app.service"
import { VerifyPhone } from "../../../../api/app.service"
import { UserCtx } from "../../../../context/userContext"
import { UserInfo } from "../../../../api/app.service"

const PersonalInfoPage: React.FC = () => {
  const [editNames, setEditNames] = useState<boolean>(false)
  const [editEmail, setEditEmail] = useState<boolean>(false)
  const [editPhone, setEditPhone] = useState<boolean>(false)
  const [editTitle, setEditTitle] = useState<boolean>(false)
  const [editQuote, setEditQuote] = useState<boolean>(false)
  const [editDescription, setEditDescription] = useState<boolean>(false)
  const [editRegion, setEditRegion] = useState<boolean>(false)
  const [editTimezone, setEditTimezone] = useState<boolean>(false)
  const [editDisplayName, setEditDisplayName] = useState<boolean>(false)
  const [emailVerified, setEmailVerified] = useState<boolean>(false)
  const [phoneVerified, setPhoneVerified] = useState<boolean>(false)
  const [verifyPhoneForm, setVerifyPhoneForm] = useState<boolean>(false)
  const [profileImgFile, setProfileImgFile] = useState<File>()
  const [awaitNewImage, setAwaitNewImage] = useState<boolean>(false)
  const [loadingPersonalInfo, setLoadingPersonalInfo] = useState<boolean>(false)
  const { user } = useContext(UserCtx)
  const {
    getUser,
    renderError,
    renderSuccess,
    setPopupNotification,
    hideAlert,
    previewImgUrl,
    setPreviewImgUrl,
  } = useContext(CommonFunctionCtx)
  const location = useLocation()
  const { startLoading, stopLoading } = useLoading()
  const [loadingColor, setLoadingColor] = useState<boolean>(false)
  const [loadingDeleteAvatar, setLoadingDeleteAvatar] = useState<boolean>(false)

  const [values, setValues] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    region: {},
    displayName: "",
    timeZone: {},
    userColor: "",
    hasAvatar: false,
    title: "",
    quote: "",
    description: "",
  })

  const [tempValues, setTempValues] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    region: {},
    displayName: "",
    timeZone: {},
    hasAvatar: false,
    title: "",
    quote: "",
    description: "",
  })

  const colors = {
    blurple: "bg-blurple",
    oceanBlue: "bg-oceanBlue",
    grassGreen: "bg-grassGreen",
    fallOrange: "bg-fallOrange",
    darkPurple: "bg-darkPurple",
    charcoalBlack: "bg-charcoalBlack",
  }

  const sendVerifyPhone = async (values: any, tempValues: any) => {
    hideAlert()
    startLoading()

    if (tempValues.phone.length < 10) {
      renderError("Phone number must be at least 10 characters long.")
      return
    }

    try {
      const promises = []

      if (values.phone !== tempValues.phone) {
        promises.push(
          UserInfo.editPhoneNumber({
            user_id: parseInt(user.user_id),
            access_token: user.access_token,
            phone_number: tempValues.phone,
          }).then((data) => setPhoneVerified(data.phone_number_verified))
        )
      }

      promises.push(
        VerifyPhone.sendPhoneCode({ access_token: user.access_token })
          .then((data) => {
            if (data.message === "complete") {
              setVerifyPhoneForm(true)
            }
          })
          .catch((err) => {
            console.error(err)
            renderError(
              err.response.data.message || "Failed to send phone verification."
            )
          })
      )

      await Promise.all(promises)

      setEditPhone(false)
    } catch (error: any) {
      console.error(
        "An error occurred while verifying the phone number:",
        error.message
      )
      renderError(
        "An error occurred while updating your phone number. Please try again."
      )
    } finally {
      stopLoading()
    }
  }

  const getPersonalInfo = () => {
    if (user) {
      setValues({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        phone: user.phone || "",
        timeZone: user.timezone || {},
        displayName: user.displayName || "",
        region: user.region || {},
        userColor: user.userColor || "",
        hasAvatar: user.hasAvatar || false,
        title: user.about?.title || "",
        quote: user.about?.quote || "",
        description: user.about?.description || "",
      })
      setTempValues({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        phone: user.phone || "",
        timeZone: user.timezone || {},
        displayName: user.displayName || "",
        region: user.region || {},
        hasAvatar: user.hasAvatar || false,
        title: user.about?.title || "",
        quote: user.about?.quote || "",
        description: user.about?.description || "",
      })
      setEmailVerified(user.emailVerified)
      setPhoneVerified(user.phoneVerified)
      stopLoading()
    }
  }

  const handleChange = (e: React.ChangeEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement

    setTempValues({ ...tempValues, [target.name]: target.value })
  }

  const handlePhoneChange = (number: string) => {
    setTempValues({ ...tempValues, phone: number })
  }

  const validEmail = () => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/
    if (tempValues.email && tempValues.email.match(mailformat)) {
      return true
    } else {
      return false
    }
  }

  const resetTempNames = () => {
    tempValues["firstName"] = values["firstName"]
    tempValues["lastName"] = values["lastName"]
    setEditNames(false)
  }

  const resetTempEmail = () => {
    tempValues["email"] = values["email"]
    setEditEmail(false)
  }

  const resetTempPhone = () => {
    tempValues["phone"] = values["phone"]
    setEditPhone(false)
  }

  const resetTempTitle = () => {
    tempValues["title"] = values["title"]
    setEditTitle(false)
  }

  const resetTempQuote = () => {
    tempValues["quote"] = values["quote"]
    setEditQuote(false)
  }

  const resetTempDescription = () => {
    tempValues["description"] = values["description"]
    setEditDescription(false)
  }

  const resetTempRegion = () => {
    tempValues["region"] = values["region"]
    setEditRegion(false)
  }

  const resetTempTimezone = (e?: React.FocusEvent<HTMLDivElement>) => {
    tempValues["timeZone"] = values["timeZone"]
    setEditTimezone(false)
  }

  const resetTempDisplayName = () => {
    tempValues["displayName"] = values["displayName"]
    setEditDisplayName(false)
  }

  const propagateNameToStream = (profileType: string | null) => {
    let streamToken: string = ""
    const streamClientId = process.env.REACT_APP_STREAM_CLIENT_ID

    let profileId: string = ""

    if (profileType === "coach") {
      streamToken = user?.coachProfile.stream_token
      profileId = user?.coachProfile?.profile_id?.toString()
    } else if (profileType === "member") {
      streamToken = user?.memberProfile.stream_token
      profileId = user?.memberProfile?.profile_id?.toString()
    }

    if (streamClientId && streamToken) {
      const client = new StreamChat(streamClientId)
      client
        .connectUser(
          {
            id: profileId,
          },
          streamToken.toString()
        )
        .then(() => {
          const updatedInfo = {
            id: profileId,
            set: {
              name: `${tempValues.firstName} ${tempValues.lastName}`,
            },
          }
          return client.partialUpdateUser(updatedInfo)
        })
        .then((updateUser) => {
          client.disconnectUser()
          renderSuccess(
            `Successfully updated name to ${updateUser?.users?.[profileId].name}`
          )
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const saveUserColor = (color: string) => {
    hideAlert()
    if (user) {
      setLoadingColor(true)
      startLoading()
      UserInfo.editUserColor({
        user_id: parseInt(user.user_id),
        access_token: user.access_token,
        user_color: color,
      })
        .then(() => {
          setLoadingColor(false)
          setValues({ ...values, userColor: color })
          getUser()
        })
        .catch((err) => {
          console.error(err)
          renderError(err.response.data.message)
          stopLoading()
        })
    }
  }

  const saveUserProfileImage = (base64String: string, type: string) => {
    hideAlert()
    if (user) {
      startLoading()
      UserInfo.editUserAccountImage({
        user_id: parseInt(user.user_id),
        access_token: user.access_token,
        base_64_string: base64String,
        file_extension: type,
      })
        .then(() => {
          getUser().then(() => {
            renderSuccess("Uploaded account image")
            setProfileImgFile(undefined)
            setAwaitNewImage(false)
          })
        })
        .catch((err) => {
          console.error(err)
          setProfileImgFile(undefined)
          renderError(err.response.data.message)
          stopLoading()
        })
    }
  }

  const sendVerifyEmail = () => {
    hideAlert()
    startLoading()
    if (user) {
      VerifyEmail.sendEmailCode({
        user_id: parseInt(user.user_id),
        pathname: `${location.pathname}${location.search}`,
      })
        .then((data: any) => {
          if (data.message === "complete") {
            setPopupNotification({
              show: true,
              title: "Verification Link Sent!",
              message:
                "A verification email has been sent to the email address associated with your account. Please check your email to verify your email address. 🎉",
              callback: null,
            })
          }
        })
        .catch((err) => {
          console.error(err)
          renderError(err.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  const deleteProfileImage = () => {
    hideAlert()
    if (user) {
      setLoadingDeleteAvatar(true)
      startLoading()
      UserInfo.deleteUserAccountImage({ user_id: parseInt(user.user_id) })
        .then(() => {
          getUser().then(() => {
            setLoadingDeleteAvatar(false)
            renderSuccess("Deleted account image")
            stopLoading()
          })
        })
        .catch((err) => {
          console.error(err)
          renderError(err.response.data.message)
          stopLoading()
        })
    }
  }

  const validateFile = (imageFile: File) => {
    if (
      (imageFile.type === "image/png" ||
        imageFile.type === "image/jpg" ||
        imageFile.type === "image/jpeg") &&
      imageFile.size <= 5000000 &&
      imageFile.size > 0
    ) {
      return true
    } else {
      return false
    }
  }

  const handleProfileImageUpload = () => {
    if (profileImgFile) {
      if (validateFile(profileImgFile)) {
        const type = profileImgFile.type.split("/")[1]
        const reader = new FileReader()
        reader.readAsDataURL(profileImgFile)
        reader.onload = function () {
          const string = (reader.result as string).split("\n").map((data) => {
            return data.split(",")
          })
          const base64 = string[0][1]
          saveUserProfileImage(base64, type)
        }
        reader.onerror = function (error) {
          console.log("Error: ", error)
        }
      } else {
        setPopupNotification({
          show: true,
          title: "Bummer! Your file is too big.",
          message:
            "Give it another try with a file under 5 megabytes, and in one of the following formats: png, jpg, or jpeg.",
          callback: null,
        })
        setProfileImgFile(undefined)
      }
    }
  }

  const handleBlur = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>,
    blurCallback: (value: boolean) => void
  ) => {
    const relatedTarget = e.relatedTarget as HTMLElement | null

    if (relatedTarget && relatedTarget.dataset.cancelButton === "true") {
      return
    }
    blurCallback(false)
  }

  const saveAll = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()
    hideAlert()

    if (!user) return

    if (!validEmail()) {
      renderError("Invalid email address")
      return
    }

    if (
      !validateFirstAndLastName(tempValues.firstName).success ||
      !validateFirstAndLastName(tempValues.lastName).success
    ) {
      renderError("Invalid name entry")
      return
    }

    const hasNameChanged =
      values.firstName !== tempValues.firstName ||
      values.lastName !== tempValues.lastName

    startLoading()
    try {
      const promises = []

      if (hasNameChanged) {
        promises.push(
          UserInfo.editFullName({
            user_id: parseInt(user.user_id),
            access_token: user.access_token,
            first_name: tempValues.firstName,
            last_name: tempValues.lastName,
          })
        )
      }

      if (values.email !== tempValues.email) {
        const emailExists = await doesEmailExist(tempValues.email)
        if (emailExists?.found) {
          renderError("Email is already in use")
          stopLoading()
          resetTempEmail()
          return
        }
        promises.push(
          UserInfo.editEmail({
            user_id: parseInt(user.user_id),
            access_token: user.access_token,
            email: tempValues.email.toLowerCase(),
          }).then((data) => setEmailVerified(data.email_verified))
        )
      }

      if (values.region !== tempValues.region) {
        promises.push(
          UserInfo.editRegion({
            user_id: parseInt(user.user_id),
            access_token: user.access_token,
            region: tempValues.region,
          })
        )
      }

      if (values.displayName !== tempValues.displayName) {
        promises.push(
          UserInfo.editDisplayName({
            user_id: parseInt(user.user_id),
            access_token: user.access_token,
            display_name: tempValues.displayName,
          })
        )
      }

      if (values.timeZone !== tempValues.timeZone) {
        promises.push(
          UserInfo.editTimezone({
            user_id: parseInt(user.user_id),
            access_token: user.access_token,
            timezone: tempValues.timeZone,
          })
        )
      }

      const aboutObject = {
        title: tempValues.title,
        quote: tempValues.quote,
        description: tempValues.description,
      }
      if (
        values.title !== tempValues.title ||
        values.quote !== tempValues.quote ||
        values.description !== tempValues.description
      ) {
        promises.push(
          UserInfo.editAbout({
            user_id: parseInt(user.user_id),
            about: aboutObject,
          })
        )
      }

      await Promise.all(promises)

      if (hasNameChanged) {
        let activeProfile = user.activeProfile
        let inactiveProfile = activeProfile === "coach" ? "member" : "coach"
        propagateNameToStream(activeProfile)
        propagateNameToStream(inactiveProfile)
      }

      getUser()
      setEditNames(false)
      setEditEmail(false)
      setEditRegion(false)
      setEditDisplayName(false)
      setEditTimezone(false)
      setEditTitle(false)
      setEditQuote(false)
      setEditDescription(false)
      renderSuccess("Your personal information is successfully updated")
    } catch (ex) {
      if (ex instanceof Error && (ex as any).response?.data?.message) {
        renderError((ex as any).response.data.message)
      } else {
        console.error("An unknown error occurred:", ex)
      }
    } finally {
      stopLoading()
    }
  }

  useEffect(() => {
    handleProfileImageUpload()
    if (profileImgFile) {
      setPreviewImgUrl(URL.createObjectURL(profileImgFile))
    }
  }, [profileImgFile])

  useEffect(() => {
    getPersonalInfo()
  }, [user])

  useScrollToTop(user)

  useEffect(() => {
    setLoadingPersonalInfo(true)
    getUser().then(() => {
      setLoadingPersonalInfo(false)
    })
  }, [])

  return (
    <div
      className={`settings-container mx-auto relative flex flex-col items-center px-[20px] pb-36 md:w-[744px] md:px-0`}
    >
      {verifyPhoneForm && (
        <VerifyPhonePopup
          phone={values?.phone}
          setVerifyPhoneForm={setVerifyPhoneForm}
          setPhoneVerified={setPhoneVerified}
        />
      )}
      {loadingPersonalInfo ? (
        <div className="flex flex-col items-center gap-[12px]">
          <Loader />
          <h3 className="mb-[4px] text-center text-[16px] font-bold">
            Loading your personal info...
          </h3>
        </div>
      ) : (
        <>
          <div className="main-shadow mb-[32px] flex w-full flex-col rounded-[16px] px-[20px] py-[24px] md:p-[48px]">
            <h4 className="mb-[16px] text-[22px] font-bold text-black md:mb-[24px] md:text-[28px] md:font-semibold">
              Details
            </h4>
            {values.hasAvatar ? (
              <div
                className="mb-[40px] flex w-full flex-col
                                items-center md:flex-row md:justify-start md:gap-[40px]"
              >
                {awaitNewImage ? (
                  <>
                    <DragDropArea
                      styling={`rounded-full w-[140px] h-[140px]`}
                      mediaFile={profileImgFile}
                      setMediaFile={setProfileImgFile}
                      mediaType="image"
                    />
                    <button
                      className="btn-primary btn-blue"
                      onClick={() => setAwaitNewImage(false)}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <div className="relative mb-[20px] h-[140px] w-[140px] rounded-full md:mb-0">
                      {previewImgUrl ? (
                        <img
                          src={previewImgUrl}
                          className="absolute left-0 top-0 flex h-full w-full
                                                    items-center justify-center rounded-full object-cover"
                          alt="avatar preview"
                        />
                      ) : (
                        <img
                          src={user.avatar_url}
                          className="absolute left-0 top-0 flex h-full w-full
                                                    items-center justify-center rounded-full object-cover"
                          alt="avatar"
                        />
                      )}
                    </div>

                    <div className="flex flex-wrap items-center justify-center gap-3">
                      <Button
                        variant="secondary"
                        onClick={() => setAwaitNewImage(true)}
                      >
                        <span className="hidden md:block">
                          Upload New Image
                        </span>
                        <span className="md:hidden">New Image</span>
                        <SvgUploadImage />
                      </Button>
                      {loadingDeleteAvatar ? (
                        <div className="ml-16">
                          <Loader />
                        </div>
                      ) : (
                        <Button
                          variant="destructive"
                          onClick={deleteProfileImage}
                        >
                          <span className="hidden md:block">
                            Delete Profile Image
                          </span>
                          <span className="md:hidden">Delete Image</span>
                          <SvgTrash />
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div
                className="mb-[40px] flex w-full flex-col
                                items-center md:flex-row md:justify-start md:gap-[129px]"
              >
                <DragDropArea
                  styling={`rounded-full w-[140px] h-[140px]`}
                  mediaFile={profileImgFile}
                  setMediaFile={setProfileImgFile}
                  mediaType="image"
                />
                <div className="flex flex-col items-center">
                  <p className="text-graySlate mb-[12px]">
                    or choose your avatar color
                  </p>
                  <div className="flex items-center gap-[8px]">
                    {loadingColor ? (
                      <Loader />
                    ) : (
                      Object.entries(colors).map(([colorName, bgColor]) => (
                        <div
                          className={`flex h-[20px] w-[20px] items-center justify-center rounded-full ${bgColor} cursor-pointer
                                                ${
                                                  colorName ===
                                                    values.userColor &&
                                                  "border border-solid border-black"
                                                }`}
                          onClick={() => saveUserColor(colorName)}
                          key={colorName}
                        ></div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            )}
            <div
              className={`mb-[16px] flex flex-col gap-[16px] md:flex-row md:gap-[14px] ${
                editNames && "md:gap-[10px]"
              }`}
            >
              <div className="flex w-full items-center gap-2">
                <div className={`default-input w-full`}>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={tempValues.firstName}
                    onChange={handleChange}
                    name="firstName"
                    id="firstName"
                    autoComplete="off"
                    onFocus={() => setEditNames(true)}
                    onBlur={(e) => handleBlur(e, setEditNames)}
                  />
                  <div className="overlay">First name</div>
                </div>
              </div>
              <div className="flex w-full items-center gap-2">
                <div className={`default-input w-full`}>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={tempValues.lastName}
                    onChange={handleChange}
                    name="lastName"
                    id="lastName"
                    autoComplete="off"
                    onFocus={() => setEditNames(true)}
                    onBlur={(e) => handleBlur(e, setEditNames)}
                  />
                  <div className="overlay">Last name</div>
                </div>
              </div>
              {editNames && (
                <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={resetTempNames}
                    data-cancel-button="true"
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <SvgClose />
                  </Button>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
              <div className="flex w-full items-center gap-2">
                <div className={`default-input w-full`}>
                  <input
                    type="text"
                    placeholder="Email"
                    value={tempValues.email}
                    onChange={handleChange}
                    name="email"
                    id="email"
                    autoComplete="off"
                    onFocus={() => setEditEmail(true)}
                    onBlur={(e) => handleBlur(e, setEditEmail)}
                  />
                  <div className="overlay">Email</div>
                </div>
              </div>
              {editEmail && (
                <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={resetTempEmail}
                    data-cancel-button="true"
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <SvgClose />
                  </Button>
                </div>
              )}
            </div>
            {emailVerified ? (
              <div className="mb-[16px] mt-[12px] flex items-center">
                <SvgCheckCircle />
                <p className="text-vividCyanGreen ml-[8px] text-base">
                  Verified Email
                </p>
              </div>
            ) : (
              <button
                className="mb-[16px] mt-[12px] flex items-center"
                onClick={sendVerifyEmail}
              >
                <SvgVerifyEmail fill="#0693E3" />
                <p className="text-cyanBlue ml-[8px] text-base">Verify Email</p>
              </button>
            )}
            <div className="flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
              <div className="flex w-full items-center gap-2">
                <div className={`default-input w-full`}>
                  <PhoneInput
                    placeholder="Enter your mobile number"
                    country="us"
                    onChange={handlePhoneChange}
                    value={tempValues.phone}
                    containerClass={`default-input w-full`}
                    inputClass={`w-full h-full rounded-lg focus:py-0 phone-input`}
                    inputProps={{ enableSearch: true, id: `phone` }}
                    onFocus={() => setEditPhone(true)}
                    onBlur={(e) => handleBlur(e, setEditPhone)}
                  ></PhoneInput>
                </div>
              </div>
              {editPhone && (
                <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={resetTempPhone}
                    data-cancel-button="true"
                  >
                    <SvgClose />
                  </Button>
                </div>
              )}
            </div>

            {phoneVerified && tempValues.phone === user.phone ? (
              <div className="mb-[16px] mt-[12px] flex cursor-pointer items-center">
                <SvgCheckCircle />
                <p className="text-vividCyanGreen ml-[8px] text-base">
                  Verified Phone
                </p>
              </div>
            ) : (
              <button
                className="mb-[16px] mt-[12px] flex cursor-pointer items-center"
                onClick={async () => {
                  try {
                    await sendVerifyPhone(values, tempValues)
                  } catch (err) {
                    console.error("An error occurred:", err)
                  }
                }}
              >
                <SvgVerifyPhone />
                <p className="text-blurple ml-[8px] text-base">
                  Click to Verify Phone
                </p>
              </button>
            )}
            <div className="flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
              <div className="flex w-full items-center gap-2">
                <div className={`default-input w-full`}>
                  <input
                    type="text"
                    placeholder="Display Name"
                    value={tempValues.displayName}
                    onChange={handleChange}
                    name="displayName"
                    id="displayName"
                    autoComplete="off"
                    onFocus={() => setEditDisplayName(true)}
                    onBlur={(e) => handleBlur(e, setEditDisplayName)}
                  />
                  <div className="overlay">Display Name</div>
                </div>
              </div>
              {editDisplayName && (
                <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={resetTempDisplayName}
                    data-cancel-button="true"
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <SvgClose />
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="main-shadow mb-[32px] flex-col rounded-[16px] p-[24px] w-full md:flex md:p-[48px]">
            <h4 className="mb-[16px] text-[22px] font-bold text-black md:mb-[24px] md:text-[28px] md:font-semibold">
              About you
            </h4>
            <div className="mb-[10px] flex items-center gap-[16px]">
              <h5 className="text-base font-bold">Tell us who you are</h5>
            </div>
            <div className="mb-[24px] flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
              <div className="flex w-full items-center gap-2">
                <div className={`default-input w-full`}>
                  <input
                    type="text"
                    placeholder="Title"
                    value={tempValues.title}
                    onChange={handleChange}
                    name="title"
                    id="title"
                    autoComplete="off"
                    onFocus={() => setEditTitle(true)}
                    onBlur={(e) => handleBlur(e, setEditTitle)}
                  />
                  <div className="overlay">Title</div>
                </div>
              </div>
              {editTitle && (
                <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={resetTempTitle}
                    data-cancel-button="true"
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <SvgClose />
                  </Button>
                </div>
              )}
            </div>
            <div className="mb-[10px] flex items-center gap-[16px]">
              <h5 className="text-base font-bold">Quote</h5>
            </div>
            <div className="mb-[24px] flex flex-col items-start gap-[16px] md:flex-row md:gap-[12px]">
              <div className="flex w-full items-start gap-2">
                <div className={`w-full`}>
                  <textarea
                    onChange={handleChange}
                    name="quote"
                    id="quote"
                    cols={30}
                    rows={8}
                    className={`text-graySlate focus:border-royalBlue w-full resize-none rounded-[10px] border-[1px] border-solid bg-white px-[20px] py-[16px] text-[16px] focus:border focus:border-solid focus:text-black focus:outline-0 ${
                      editQuote ? "border-royalBlue" : "border-grayCloud"
                    }`}
                    placeholder="Here, we recommend you add a quote. This can be something you live by, a bit of inspiration for potential clients, or a sliver of wisdom."
                    onFocus={() => setEditQuote(true)}
                    onBlur={(e) => handleBlur(e, setEditQuote)}
                    value={tempValues.quote}
                  ></textarea>
                </div>
              </div>
              {editQuote && (
                <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={resetTempQuote}
                    data-cancel-button="true"
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <SvgClose />
                  </Button>
                </div>
              )}
            </div>
            <div className="mb-[10px] flex items-center gap-[16px]">
              <h5 className="text-base font-bold">Description</h5>
            </div>
            <div className="mb-[24px] flex flex-col items-start gap-[16px] md:flex-row md:gap-[12px]">
              <div className="flex w-full items-start gap-2">
                <div className={`w-full`}>
                  <textarea
                    onChange={handleChange}
                    name="description"
                    id="description"
                    cols={30}
                    rows={8}
                    className={`text-graySlate focus:border-royalBlue w-full resize-none rounded-[10px] border-[1px] border-solid bg-white px-[20px] py-[16px] text-[16px] focus:border focus:border-solid focus:text-black focus:outline-0 ${
                      editDescription ? "border-royalBlue" : "border-grayCloud"
                    }`}
                    placeholder="This is where you'll put a brief bio. We don't need to know what hospital you were born at, just what makes you a 100% rockstar coach!"
                    onFocus={() => setEditDescription(true)}
                    onBlur={(e) => handleBlur(e, setEditDescription)}
                    value={tempValues.description}
                  ></textarea>
                </div>
              </div>
              {editDescription && (
                <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={resetTempDescription}
                    data-cancel-button="true"
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <SvgClose />
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="main-shadow mb-[32px] flex-col rounded-[16px] p-[24px] w-full md:flex md:p-[48px]">
            <h4 className="mb-[16px] text-[22px] font-bold text-black md:mb-[16px] md:text-[28px] md:font-semibold">
              Region
            </h4>
            <p className="text-graySlate mb-[24px] text-base">
              Where are you located?
            </p>
            <div className="flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
              <div className="flex w-full items-center gap-2">
                <Select
                  className="basic-single w-full"
                  classNamePrefix="select"
                  value={tempValues.region}
                  options={regions}
                  menuPlacement="top"
                  name="region"
                  id="region"
                  onFocus={() => setEditRegion(true)}
                  onBlur={(e) => handleBlur(e, setEditRegion)}
                  onChange={(e: any) =>
                    setTempValues({ ...tempValues, region: e })
                  }
                />
              </div>
              {editRegion && (
                <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={resetTempRegion}
                    data-cancel-button="true"
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <SvgClose />
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="main-shadow flex w-full flex-col rounded-[16px] px-[20px] py-[24px] md:p-[48px]">
            <h4 className="mb-[16px] text-[22px] font-bold text-black md:mb-[24px] md:text-[28px] md:font-semibold">
              Timezone
            </h4>
            <p className="text-graySlate mb-[12px] w-[271px] text-base md:mb-[16px] md:w-[405px]">
              Your times, account data, and analytics information will be
              displayed in the timezone you select below.
            </p>
            <div className="flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
              <div className="flex w-full items-center gap-2">
                {tempValues.timeZone && (
                  <TimezoneSelect
                    className="basic-single w-full"
                    classNamePrefix="select"
                    value={tempValues.timeZone}
                    menuPlacement="top"
                    name="timezone"
                    id="timezone"
                    onFocus={() => setEditTimezone(true)}
                    onBlur={(e) => handleBlur(e, setEditTimezone)}
                    onChange={(e) =>
                      setTempValues({ ...tempValues, timeZone: e })
                    }
                    timezones={{
                      ...allTimezones,
                      [user?.timezone.value]: user?.timezone.value,
                    }}
                  />
                )}
              </div>
              {editTimezone && (
                <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => resetTempTimezone()}
                    data-cancel-button="true"
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <SvgClose />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <Button className={`fixed bottom-5`} size="lg" onClick={saveAll}>
        Save Changes
      </Button>
    </div>
  )
}

export default PersonalInfoPage
