import React from "react"
import JessicaTestimonial from "./images/Jessica.png"
import FaithTestimonial from "./images/Faith.png"
import BernadetteTestimonial from "./images/Bernadette.png"

const IINTestimonials: React.FC = () => {
  return (
    <div className="relative w-full md:pt-[96px] pb-10 md:px-[140px] px-4 sm:px-8 border-b-[88px] border-b-[#fcb900] ">
      {/* Title */}
      <h2 className="flex flex-col items-center text-left text-black text-[48px] font-bold md:mb-12 mb-6 px-6">
        A platform trusted by coaches
      </h2>

      {/* Testimonials */}
      <div className="flex justify-center flex-wrap md:gap-8 w-full max-w-6xl mx-auto">
        {/* Testimonial 1 */}
        <div className="flex flex-col gap-4 items-start text-left p-8 md:w-[360px] sm:w-full">
          <p className="text-[#0d0d0d] text-[22px] font-bold flex-grow">
            “[The] autopay feature is a blessing...I set up my programs, book
            sessions, and let Zoee handle the rest.”
          </p>
          <div className="flex items-center gap-4 mt-auto">
            <img
              className="w-14 h-14 rounded-full object-cover"
              src={JessicaTestimonial}
              alt="Jessica Testimonial"
            />
            <div>
              <p className="text-[#0f1728] text-[18px] font-semibold">
                Jessica P.
              </p>
              <p className="text-[#667084] text-[16px]">E-Learning Coach</p>
            </div>
          </div>
        </div>

        {/* Testimonial 2 */}
        <div className="flex flex-col gap-4 items-start text-left p-8 md:w-[360px] sm:w-full">
          <p className="text-[#0d0d0d] text-[22px] font-bold flex-grow">
            “As a coach, it’s critical that processes are made simple in my
            business so I can focus on coaching my clients. Zoee makes it easy
            for me to do that!”
          </p>
          <div className="flex items-center gap-4 mt-auto">
            <img
              className="w-14 h-14 rounded-full object-cover"
              src={FaithTestimonial}
              alt="Faith Testimonial"
            />
            <div>
              <p className="text-[#0f1728] text-[18px] font-semibold">
                Faith Armstrong
              </p>
              <p className="text-[#667084] text-[16px]">
                Strategic Marketing Coach
              </p>
            </div>
          </div>
        </div>

        {/* Testimonial 3 */}
        <div className="flex flex-col gap-4 items-start text-left p-8 md:w-[360px] sm:w-full">
          <p className="text-[#0d0d0d] text-[22px] font-bold flex-grow">
            “Zoee is an amazing platform for coaches to spend more time doing
            what they LOVE: coach their clients.”
          </p>
          <div className="flex items-center gap-4 mt-auto">
            <img
              className="w-14 h-14 rounded-full object-cover"
              src={BernadetteTestimonial}
              alt="Bernadette Testimonial"
            />
            <div>
              <p className="text-[#0f1728] text-[18px] font-semibold">
                Bernadette R.
              </p>
              <p className="text-[#667084] text-[16px]">Professional Coach</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-full h-[88px] mt-16 bg-[#fcb900]" /> */}
    </div>
  )
}

export default IINTestimonials
