import React from "react"
// import NewCardForm from "../../../components/account-settings/payment-methods/NewCardForm"

import CloseModal from "../../CloseModal"

interface Props {
  callback: any
  options: any
  getPaymentMethods?: any
  stripeRedirectParams?: string | null
}

export default function PaymentMethodModal({
  callback,
  options,
  getPaymentMethods,
  stripeRedirectParams,
}: Props) {
  const [newCardField, setNewCardField] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (options.clientSecret) {
      setNewCardField(true)
    }
  }, [options])

  return (
    <div className="fixed top-0 left-0 w-screen h-screen zoee-backdrop-filter z-[4001] flex items-center justify-center pb-[80px] md:pb-0">
      <div
        className="py-[40px] px-[16px] md:px-[32px] md:w-[771px] w-full mx-[20px] bg-white 
                main-shadow rounded-[16px] flex flex-col relative"
      >
        <CloseModal
          callback={() => callback(false)}
          styling="absolute top-[20px] md:top-[24px] right-[20px] md:right-[24px] cursor-pointer"
        />
        <h3 className="font-bold text-[22px] mb-[16px] text-center md:text-left">
          Add New Payment Method
        </h3>
        {/* {newCardField && (
          <Elements stripe={stripePromise} options={options}>
            <NewCardForm
              setNewCardField={setNewCardField}
              callback={callback}
              getPaymentMethods={getPaymentMethods}
              stripeRedirectParams={stripeRedirectParams}
            />
          </Elements>
        )} */}
      </div>
    </div>
  )
}
