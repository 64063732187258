import React, { useEffect, useState } from "react"
import BentoBoxFive from "./BentoBoxFive"
import BentoBoxFour from "./BentoBoxFour"
import BentoBoxThree from "./BentoBoxThree"
import classes from "./PublicOverviewBento.module.css"
import ListSocialMedia from "./ListSocialMedia"
import OverviewServices from "./OverviewServices"
import ProfileSchedulerPopup from "./ProfileSchedulerPopup"
import NewsletterOptInForm from "./newsletter/NewsletterOptInForm"
import { BlogPost } from "../../models/blog.interface"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ProfileRequests } from "../../api/app.service"

interface Props {
  renderServices: () => void
  setActiveTab: any
}

export default function PublicOverviewBento({
  renderServices,
  setActiveTab,
}: Props) {
  const [, setLoadingPosts] = React.useState<boolean>(false)
  const [post, setPost] = React.useState<BlogPost | null>(null)
  const [allPosts, setAllPosts] = React.useState<BlogPost[]>([])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const { coachPublicProfileData, services } = React.useContext(
    CoachPublicProfileContext
  )
  const backgroundColor =
    coachPublicProfileData?.profile_information?.profileColor

  const itemStyle = {
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "0 0 20px 5px rgba(150, 150, 150, 0.2)",
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const getCoachBlogPosts = async () => {
    setLoadingPosts(true)
    await ProfileRequests.getCoachBlogPosts({
      coach_profile_id: parseInt(coachPublicProfileData?.coach_profile_id),
    })
      .then((data) => {
        const approvedPosts = data.blog_posts.filter(
          (post) => post.status === "approved" && post.published
        )
        setPost(approvedPosts[0] || null)
        setAllPosts(data.blog_posts)
        setLoadingPosts(false)
      })
      .catch((err) => {
        renderError(err.response.data.message)
        setLoadingPosts(false)
      })
  }

  React.useEffect(() => {
    getCoachBlogPosts()
  }, [coachPublicProfileData])

  return (
    <>
      <div className={classes.container}>
        {windowWidth >= 1280 && (
          <div
            style={{ ...itemStyle, gridArea: "cons" }}
            className="w-[300px] h-[601px]"
          >
            <ProfileSchedulerPopup
              consultation={services.find(
                (service: any) =>
                  service.service_details.type === "consultation"
              )}
            />
          </div>
        )}
        <div
          style={{ ...itemStyle, gridArea: "serv" }}
          className="w-[300px] md:w-[624px] h-[280px]"
        >
          <OverviewServices
            renderServices={renderServices}
            services={services}
            isEditable={false}
            coachColor={backgroundColor}
          />
        </div>
        <div
          style={{ ...itemStyle, gridArea: "vide" }}
          className="w-[300px] h-[460px]"
        >
          <BentoBoxThree
            endpointSlug={coachPublicProfileData.endpoint_slug}
            coachData={coachPublicProfileData}
            coachColor={backgroundColor}
          />
        </div>
        <div
          style={{ ...itemStyle, gridArea: "blog" }}
          className="w-[300px] h-[300px]"
        >
          <BentoBoxFour
            post={post as BlogPost | null}
            blogTag
            isEditable
            endpointSlug={coachPublicProfileData.endpoint_slug}
            coachData={coachPublicProfileData}
            coachColor={backgroundColor}
            allPosts={allPosts}
          />
        </div>
        <div
          style={{ ...itemStyle, gridArea: "test" }}
          className="w-[300px] h-[300px]"
        >
          <BentoBoxFive
            endpointSlug={coachPublicProfileData.endpoint_slug}
            coachData={coachPublicProfileData}
            coachColor={backgroundColor}
            setActiveTab={setActiveTab}
          />
        </div>
        <div
          style={{ ...itemStyle, gridArea: "soci" }}
          className="w-[300px] h-[120px]"
        >
          <ListSocialMedia
            bgColor={backgroundColor}
            coachPublicProfileId={
              coachPublicProfileData?.coach_public_profile_id
            }
          />
        </div>
      </div>
      {coachPublicProfileData?.newsletter_opt_in_enabled && (
        <div className="w-full px-6 flex justify-center mb-8">
          <div className="max-w-[1250px] w-full">
            <NewsletterOptInForm />
          </div>
        </div>
      )}
    </>
  )
}
