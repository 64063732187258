import React from "react"
import ProductImage3 from "./images/image 51.png"

const IINWhy: React.FC = () => {
  return (
    <div
      className="py-[56px] md:py-[96px] flex flex-col items-center justify-center overflow-hidden md:px-[144px] px-4 sm:px-8 max-w-screen-xl mx-auto"
    >
      <div className="w-full flex flex-col md:flex-row items-center gap-10 md:gap-16">
        <div className="w-full md:w-1/2 flex flex-col gap-4 md:gap-6">
          <div
            className="flex flex-col items-start"
          >
            <h2
              className="mb-1 text-[#0D0D0D] font-bold text-5xl md:text-6xl leading-tight tracking-[-0.06em] w-full"
            >
              Why Business Blueprint <br />
              <span className="font-normal italic">for</span> Health Coaches
            </h2>
          </div>
          <div className="flex flex-col gap-4 mt-4">
            <p className="text-[#0D0D0D] font-[BeausiteClassic] font-normal text-[22px] leading-[26.4px] text-left">
              This program was created by IIN coaches for IIN coaches. Developed
              around a deep understanding of the needs and challenges of our
              community, this program was designed to support YOU! <br /> <br />
              IIN has been supporting Health Coaches for over 30 years. As the
              pioneers of the industry, we understand it inside and out and have
              trained many well-known graduates who’ve gone on to build
              incredibly successful businesses. <br /> <br />
              You’ll be building your business in the company of talented and
              passionate IIN alumni – an exclusive opportunity to grow and
              connect with your community​. <br /> <br />
              You’ll learn from real, practicing Health Coaches who’ve defined
              what success means for them and built a fulfilling and thriving
              career around it. No gimmicks, empty promises, or aggressive sales
              tactics.​
            </p>
          </div>
        </div>

        {/* Right Section - Image */}
        <div className="w-full lg:w-2/5 h-96 rounded-lg overflow-hidden ml-auto">
          <img
            src={ProductImage3}
            alt="Woman laughing with a group of coaches"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  )
}

export default IINWhy
