import React from "react"
import { Link } from "react-router-dom"
import CoachImg from "../../../../../../assets/images/BernadetteCoach.svg"
import { SvgZoeeLogoTM } from "../../../../../icons/logos"
import ICFLogo from "../../../../../../assets/images/ICFLogoWText.svg"
import CustomSupport from "../../../../../../assets/images/capterra-badges/sa-customer_support-2023.png"
import BestValue from "../../../../../../assets/images/capterra-badges/ca-value-2023.png"
import CapteraRating from "../../../../../../assets/images/capterra-badges/Captera-rating.svg"

const badges: { src: string; alt: string; w: string; link: string }[] = [
  {
    src: ICFLogo,
    alt: "Badge: International Coaching Federation",
    w: "112",
    link: "https://coachingfederation.org/",
  },
  {
    src: CustomSupport,
    alt: "Badge: Best Customer Support 2023",
    w: "48",
    link: "https://www.capterra.com/p/10002877/Zoee/reviews/",
  },
  {
    src: BestValue,
    alt: "Badge: Best Value 2024",
    w: "48",
    link: "https://www.capterra.com/p/10002877/Zoee/reviews/",
  },
  {
    src: CapteraRating,
    alt: "Badge: 4.8 Star Rating",
    w: "124",
    link: "https://www.capterra.com/p/10002877/Zoee/reviews/",
  },
]

const RegularFlow = () => {
  return (
    <div className="md:w-[500px] h-fit md:p-10 px-4 py-10 flex flex-col gap-10">
      <SvgZoeeLogoTM className="h-6 w-[125px]" />
      <div>
        <h1 className="text-[32px] font-bold">
          The All-in-One Solution: <br className="md:block hidden" />
          <span className="text-[#4750F5]">Less Admin, More Coaching</span>
        </h1>
        <p className="text-lg text-[#3A434A]">
          Join hundreds of coaches & consultants who trust Zoee to run their
          business.
        </p>
        <div className="hidden md:block border-l-[3px] border-[#4750F5] pl-3 mt-6 mb-4">
          “Zoee is an amazing platform for coaches to spend more time doing what
          they LOVE - coach their clients and less time on the calendar worrying
          about invoicing and scheduling overlaps.”
        </div>
        <div className="hidden md:flex gap-1.5">
          <img
            src={CoachImg}
            alt="Coach"
            className="h-12 w-12"
          />
          <div>
            <h3 className="text-lg font-bold">Bernadette R.</h3>
            <h4 className="text-base">Professional Coach</h4>
          </div>
        </div>
      </div>
      <div className="flex justify-between md:justify-center md:flex-wrap md:gap-4">
        {badges.map(({ src, alt, w, link }, idx) => (
          <Link
            key={`link${idx}`}
            to={link}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={`w-[${w}] h-12 max-w-full`}
              src={src}
              alt={alt}
            />
          </Link>
        ))}
      </div>
    </div>
  )
}

export default RegularFlow
