import React, { useEffect, useState } from "react"
import Date13 from "../../../../assets/images/date13.svg"
import IINTeam from "../../../../assets/images/IINTeam.png"
import MailBox from "../../../../assets/images/MailBox.svg"
import useLoading from "../../../../hooks/useLoading"
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext"
import { IINZoeeLogo } from "../../../icons/logos/zoee-iin-logo"
import { isLocalStorageAvailable } from "../../../../utils/isLocalStorageAvailable"
import { UserCtx } from "../../../../context/userContext"
import { UserInfo } from "../../../../api/app.service"
import { useIntercom } from "react-use-intercom"
import { Link } from "react-router-dom"

const EnrollmentFinalStep = () => {
  const { shutdown } = useIntercom()
  useEffect(() => {
    shutdown()
  }, [shutdown])
  const { user, clearUser } = React.useContext(UserCtx)
  const { startLoading, stopLoading } = useLoading()
  const { endActiveSession } = React.useContext(CommonFunctionCtx)

  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const logout = async () => {
    if (user && !isLoggingOut) {
      setIsLoggingOut(true)
      startLoading()
      await UserInfo.updateNextStepsValues({
        user_id: parseInt(user.user_id),
        active_profile_id: user.activeProfileId,
        created_chat: false,
        profile_access_status: "",
      })
        .then(() => {
          endActiveSession()
        })
        .then(() => {
          if (isLocalStorageAvailable()) {
            localStorage.removeItem("user")
          }
          clearUser()
        })
        .catch((ex) => {
          console.log(ex)
          stopLoading()
        })
        .finally(() => {
          setIsLoggingOut(false)
        })
    }
  }

  useEffect(() => {
    if (user && !isLoggingOut) {
      logout()
    }
  }, [user])

  return (
    <div className="h-fit px-5 md:px-0 flex flex-col items-center">
      <div className="w-full flex flex-col justify-between items-center p-4 md:py-4 md:px-[72px] md:flex-row border-b border-[#D1D9E0]">
        <Link to="/IINBusinessBlueprint">
          <IINZoeeLogo />
        </Link>
      </div>
      <div className="flex-grow w-[1000px] max-w-full mt-10">
        <div className="flex flex-col items-center py-2">
          <h1 className="text-center font-bold text-[36px]">
            Congratulations! You're enrolled in the Business Blueprint for
            Health Coaches!
          </h1>
          <p className="text-[22px] text-center">
            Order confirmation will be emailed to you by no-reply@zoee.com.
          </p>
          <img
            className="w-[600px] h-[333px] md:my-8"
            src={IINTeam}
            alt="IIN team"
          />
          <p className="text-[18px] text-center max-w-full">
            Do you have questions about the program? Contact IIN support at{" "}
            <a
              href="mailto:businessblueprint@integrativenutrition.com"
              target="_blank"
              rel="noreferrer"
              className="underline break-words"
            >
              businessblueprint@integrativenutrition.com
            </a>
          </p>
        </div>
        <hr className="my-10" />
        <div className="mb-[32px] md:mb-[50px]">
          <h3 className="text-center text-[36px] mb-10">
            Here’s what happens next
          </h3>
          <div className="flex gap-8 flex-wrap items-center justify-center">
            <div className="w-[484px] max-w-full mb-2">
              <div className="flex items-center gap-4">
                <img
                  src={Date13}
                  alt="date 13"
                />
                <h4 className="text-[22px] font-bold">Mark Your calendar!</h4>
              </div>
              <p className="text-[18px]">
                Log into Zoee.com on January 13, 2025 to start your program.{" "}
              </p>
            </div>

            <div className="w-[484px] max-w-full mb-2">
              <div className="flex items-center gap-4">
                <img
                  src={MailBox}
                  alt="mail box"
                />
                <h4 className="text-[22px] font-bold">Check Your Email</h4>
              </div>
              <p className="text-[18px]">
                Program details will be sent to your inbox as the start date
                nears Jan 13th. Be sure to also check your spam.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnrollmentFinalStep
