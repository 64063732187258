import React from "react"
import BBHCDemoVideo from "./BBHCDemoVideo"

const IINDemo: React.FC = () => {
  return (
    <div
      className="relative w-full md:pt-[96px] pb-10 px-0 sm:px-8 md:px-[140px] flex flex-col lg:flex-row justify-between gap-8 px-4 py-[120px]"
      style={{ fontFamily: "BeausiteClassic" }}
    >
      {/* Left Section - Video */}
      <div className="hidden md:block w-full lg:w-1/2 aspect-w-16 aspect-h-9 bg-transparent rounded-lg overflow-hidden max-w-[600px] max-h-[400px] mb-6 mt-14">
        <BBHCDemoVideo />
      </div>
      {/* Right Section - Title and Text */}
      <div className="flex flex-col gap-6 lg:w-2/3 text-left items-center mt-6">
        <h2
          className="text-black font-bold leading-tight"
          style={{
            fontSize: "48px",
            lineHeight: "1.2",
          }}
        >
          <span>Business Blueprint for</span> <br />
          <span>Health Coaches: A Guided</span> <br />
          <span>Tour of the Zoee Platform</span>
        </h2>

        <p
          className="text-black font-normal"
          style={{
            fontSize: "22px",
            lineHeight: "1.5",
          }}
        >
          • Program Introduction: Explore the essential tools Health <br />
          Coaches need for success.
          <br />
          • Live Platform Demo: Get an inside view of the Zoee <br />
          platform with a guided walk-through. <br />
          • Features and Benefits: Discover how Zoee supports <br />
          Health Coaches in scaling and streamlining their <br />
          businesses.
        </p>
      </div>{" "}
      <div className="md:hidden flex items-center justify-center w-full h-auto bg-transparent rounded-lg mx-0 px-0">
        {" "}
        <div className="w-[500px] h-[300px">
          <BBHCDemoVideo />
        </div>
      </div>
    </div>
  )
}

export default IINDemo
