import React, { useState } from "react"
import { SvgPlus } from "../icons"

const IINFAQs: React.FC = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

  const toggleFAQ = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  const faqs = [
    {
      question:
        "I’m new to coaching and haven’t worked with paid clients yet – is this program right for me?",
      answer:
        "Yes! Whether you have many years of coaching experience or are preparing to work with your first client, this program will help you get your practice up and running fast, while supporting you with business strategy, systems, and processes that you can use to connect with, enroll, and coach clients.",
    },
    {
      question: "Can I join the program if I’m not an IIN graduate?",
      answer:
        "This program is designed for IIN alumni by IIN alumni. However, we will not turn away coaches from other training programs who are motivated to take this program.",
    },
    {
      question: "I recently graduated. Is this program suitable for new coaches? ",
      answer:
        "Absolutely! Whether you're just starting out or have years of experience, this program equips you with the tools and strategies to hit the ground running, from creating your signature program to filling it with your ideal clients for a sustainable coaching income. You don’t even need a website—Zoee acts as your landing page and digital storefront, complete with booking, messaging, blogging, video uploads, social links, and space for client testimonials. It’s the easiest way to establish your online presence, start connecting with clients, and build your coaching business.​",
    },
    {
      question: "What is Zoee, and how does it help with my practice?",
      answer:
        "Zoee is an all-in-one practice management platform included in the Business Blueprint for Health Coaches. It allows you to: set up and promote your coaching services, schedule sessions with clients, collect payments securely, meet with clients via video telehealth, share notes and forms with clients, and more. Zoee is designed to streamline the day-to-day management of your coaching practice, saving you time and effort.​",
    },
    {
      question:
        "How does the Zoee platform integrate into the Business Blueprint for Health Coaches? ",
      answer:
        "The Business Blueprint for Health Coaches provides a structured roadmap to launch and grow your coaching business, while Zoee offers the tools to implement and manage it seamlessly. Created by an IIN graduate, Zoee complements the Blueprint by giving you an all-in-one platform to manage scheduling, client communication, enrollment, payments, and even marketing. Together, they form a powerful duo: the Business Blueprint for Health Coaches gives you weekly action steps and challenges, while ensuring you have the technology to execute them effectively for maximum results with way less overwhelm. ",
    },
    {
      question:
        "Can the Business Blueprint and Zoee support my existing business?",
      answer:
        "Yes! This program is perfect for streamlining and scaling your current coaching practice. The Business Blueprint for Health Coaches provides you with the strategies and resources to take your practice to the next level, such as by diversifying your offerings, refining your pricing strategy, and establishing a highly effective client creation process. Zoee integrates seamlessly with your existing tools, syncing with your calendars and allowing effortless admin, from client onboarding (discovery sessions, intake forms, payment processing) to service fulfillment (sessions, communication, scheduling). Whether you want to optimize operations, improve client experience, or explore new growth opportunities, the Blueprint and Zoee provide the framework and functionality to take your business to new heights. ",
    },
    {
      question: "As a Health Coach, I need to make sure my work with clients is confidential. How does Zoee comply with privacy regulations and security needs?",
      answer:
        "Zoee prioritizes client confidentiality with robust security measures. It uses 100ms for video sessions, ensuring encrypted communication and data storage compliant with industry standards. No Protected Health Information (PHI) is stored without explicit permission, and all access is tightly controlled and audited. Additionally, Zoee consolidates all client communications—video sessions, messaging, and note-taking—in one secure, HIPAA-compliant platform, so you can focus on coaching with peace of mind. ​",
    },
    {
      question: "When does this program start and end?",
      answer:
        "The Business Blueprint for Health Coaches starts January 13, 2025, and concludes April 13, 2025.​",
    },
    {
      question: "What type of content can I expect to receive?",
      answer: "Each week, you’ll receive one chapter of a comprehensive workbook filled with exercises and activities designed to help you take purposeful action. This isn’t about passively learning more theories and concepts—it’s about what coaches need most to build their businesses: intelligent, inspired steps in the direction of their dreams. The workbook systematically helps you clear blocks, implement proven strategies, and complete pivotal milestones to actualize your business goals. Curious to see what that's like? Click here to receive a sample workbook activity and see for yourself."
    },
    {
      question: "What is the refund policy for this program?",
      answer:
      "There is a five-day refund window from the start date of the program. After five days, no refunds will be issued.​",
    },
    {
      question:
      "What kind of time commitment does the program require each week, and is it flexible?",
      answer:
      "The program is designed to fit your schedule, no matter how busy or unpredictable it may be. You’ll need about 4–5 hours per week to fully engage in the experience, including workbook activities, weekly challenges, and accountability pod calls. Since you choose when to work on these activities, it’s as flexible as your business itself— and just like your coaching practice, consistent effort is key. Additionally, during Weeks 1, 6, and 12, there are 90-minute webinars to attend (recordings will be made available). ",
    },
    {
      question: "When are the live webinars going to be held?",
      answer:
      "There are three 90-minute live group interactive webinars in this program. They are led by our expert coaches and will be held during weeks 1, 6, and 12. Please see the Program Schedule for call dates and times. If you’re unable to attend live, a recording will be provided.​",
    },
    {
      question: "How do weekly challenges work?",
      answer:
      "Each week, you’ll be given a challenge designed to help you take concrete steps toward building your business. These challenges are complemented by discussion prompts to share your progress and receive feedback from the community. Completing the challenges will ensure you stay focused and motivated throughout the program.​",
    },
    {
      question: "Do I need to complete all the tasks in each chapter?",
      answer:
      "While participation is not graded, completion of all tasks is required if you want to experience the full benefit of this program. The weekly tasks and challenges are designed to help you take actionable steps toward building your business and will give you the hands-on experience you need to move forward with your health coaching practice. However, if you’re unable to complete something, don’t worry – you can revisit it later as you progress.​",
    },
    {
      question:
      "What support will I receive? Can I connect with other coaches for accountability, guidance, and collaboration?",
      answer:
      "In addition to our dedicated coaching support team, you’ll benefit from weekly live accountability pods for peer-to-peer interaction (with a private chat) and expert-moderated discussion boards to share your work, get feedback, and receive guidance from professional coaches. Weekly emails outline key actions to keep you on track. Zoee facilitates all of this within its platform, where you can collaborate and network with other coaches in the program. We ensure you’re never alone on your journey to a thriving coaching practice.  ",
    },
    {
      question: "How do I connect with other coaches in the Bootcamp?",
      answer:
      "Throughout the program, you’ll be introduced to other coaches on Zoee with opportunities for collaboration, referrals, and partnership.​",
    },
    {
      question:
      "Is there a transfer option available if I can’t complete the program? Can I receive a refund if needed?",
      answer:
      "Once enrolled in the program, there are no transfer options available. You’re required to complete the program by April 13, 2025. Please note that refunds are not available after January 17, 2025.",
    },
    {
      question: "What happens after I complete the Bootcamp?",
      answer:
      "After completing the program, you’ll have the tools, knowledge, and community support to move forward with your business. You’ll also be encouraged to continue evolving your practice, maintaining ongoing business development, and taking the next steps toward long-term success.​",
    },
  ]
  
  return (
    <div
      className="flex flex-col md:flex-row items-start justify-between w-full py-[56px] md:py-[96px] gap-10 mx-auto md:px-[144px] px-4 sm:px-8 max-w-screen-xl mx-auto"
    >
      {/* Left Section - Title and Contact */}
      <div className="md:w-[400px] flex flex-col gap-4"> 
        <h2 className="text-black text-[48px] font-bold leading-tight">
          Frequently Asked Questions
        </h2>
        <p className="text-black text-[18px] font-normal">
          Not seeing your question here? Contact IIN program support at{" "}
          <a
            href="mailto:businessblueprint@integrativenutrition.com"
            className="underline font-bold break-words text-vividRed"
          >
            businessblueprint@integrativenutrition.com
          </a>
        </p>
      </div>

      {/* Right Section - FAQs */}
      <div className="flex flex-col w-full lg:w-2/3 gap-6">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="flex flex-col"
          >
            <div className="flex justify-between items-center">
              <p className="text-black text-[24px] font-bold">{faq.question}</p>
              <button
                onClick={() => toggleFAQ(index)}
                aria-label={`Toggle answer for: ${faq.question}`}
                className="w-6 h-6"
              >
                <SvgPlus
                  className={`transform ${
                    expandedIndex === index ? "rotate-45" : ""
                  }`}
                />
              </button>
            </div>
            {expandedIndex === index && (
              <p className="text-black text-[24px] font-normal mt-4">
                {faq.answer}
              </p>
            )}
            <hr className="border-t border-[#383838] mt-4" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default IINFAQs
