import * as React from "react"

const passwordReqList = [
  "Minimum length: 8 characters",
  "Contains at least 1 number",
  "Contains at least 1 special character",
  "Contains at least 1 uppercase letter",
  "Contains at least 1 lowercase letter",
]

interface Props {
  validEntree: string
  bottomMargin?: string
  setIsEntryValid?: (statement: boolean) => void
}

export default function PasswordRequirements({ validEntree, bottomMargin, setIsEntryValid }: Props) {
  const validateEntree = (entree: string) => {
    const containsNumber = /\d/.test(entree)
    const containsSpecialChar = /[^a-zA-Z0-9]/.test(entree)
    const containsUppercase = /[A-Z]/.test(entree)
    const containsLowercase = /[a-z]/.test(entree)
    const entreeLength = entree.length >= 8

    return [
      entreeLength,
      containsNumber,
      containsSpecialChar,
      containsUppercase,
      containsLowercase,
    ]
  }

  React.useEffect(() => {
    if (setIsEntryValid) {
      setIsEntryValid(validateEntree(validEntree).every((el) => el === true))
    }
  }, [validEntree])

  return (
    <div
      className={`border-[1px] border-color-[black] bg-white rounded-[10px] p-[16px] mb-${bottomMargin} md:w-fit`}
    >
      <p className="text-sm md:text-[18px] font-bold text-[#7f8184]">
        Password requirements:
      </p>
      <ul className="pl-8 text-xs md:text-sm">
        {passwordReqList.map((item, idx) => (
          <li
            key={idx}
            className={`${
              validateEntree(validEntree)[idx]
                ? "text-[green] before:content-['✔'] before:mr-2 before:ml-[-15px] list-none"
                : "text-[red] list-disc"
            } `}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}