import axios, { type AxiosResponse } from "axios";
import type {
  CoachCard,
  CoachPublicProfileResponseType,
  CoachPublicProfileSlugType,
  CoachTag,
  ContactFormType,
  MessageResponseType,
} from "../models/public.interface";

// INSTANCES

const public_instance = axios.create({
  headers: {
    Authorization: "",
  },
  baseURL: process.env.REACT_APP_API,
  timeout: 30000,
});

// REQUESTS

const public_requests = {
  post: async (url: string, body: {}) =>
    await public_instance.post(url, body).then((response: AxiosResponse) =>
      response.data
    ),
};

// API Calls

export const MarketingRequests = {
  submitContactForm: (object: ContactFormType): Promise<MessageResponseType> =>
    public_requests.post("submitContactForm", object),
};

export const PublicProfileRequests = {
  getCoachPublicProfile: (
    object: CoachPublicProfileSlugType,
  ): Promise<CoachPublicProfileResponseType> =>
    public_requests.post("getCoachPublicProfile", object),
  getCoachTags: (): Promise<CoachTag[]> =>
    public_requests.post("getCoachTags", {}),
  getCoaches: (
    query: string = "",
    tags: number[] = [],
    page: number = 1,
    organization: string | null,
    marketplace_visibility: number,
  ): Promise<CoachCard[]> => {
    return public_requests.post("getCoaches", {
      query,
      tags,
      page,
      organization,
      marketplace_visibility
    });
  },
};
