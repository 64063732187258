import React from "react"
import BBHCWaitlist from "./BBHCWaitlist"

const IINJotform: React.FC = () => {
  return (
    <div
      className="flex flex-col items-center justify-center w-full py-[56px] md:py-[96px] gap-10 mx-auto md:px-[144px] px-4 sm:px-8"
    >
      {/* Title and Subtitle */}
      <div className="flex flex-col gap-4 text-center">
        <h1
          className="text-[#0D0D0D] font-bold text-4xl md:text-5xl leading-tight tracking-[-0.06em]"
          style={{
            marginBottom: "4px",
          }}
        >
          Can’t make it to the January
          <br />
          Business Blueprint <span className="italic font-thin">for </span>
          <span>Health Coaches</span>?
        </h1>
        <p className="text-black text-[22px] font-normal mt-[-6px]">
          Join the waitlist to get notified about future program dates!
        </p>
      </div>

      {/* Jotform Embed Placeholder */}
      <div className="w-full max-w-md h-auto flex flex-col justify-center items-center  rounded-lg">
        <BBHCWaitlist />
      </div>
    </div>
  )
}

export default IINJotform
