import React, { useState } from "react"
import DeleteDialogueModal from "../DialogueModal"
import ServiceCardBannerOverlay from "./ServiceCardBannerOverlay"
import { UserCtx } from "../../context/userContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ServiceRequests } from "../../api/app.service"
import useLoading from "../../hooks/useLoading"
import { Label } from "../ui/label"
import { Switch } from "../ui/switch"
import { Badge } from "../ui/badge"
import Loader from "../ui/loader"
import moment from "moment"
import {
  SvgArchive,
  SvgCheckCircle,
  SvgEdit,
  SvgLearn,
  SvgShare,
} from "../icons/"
import { Link } from "react-router-dom"
import { Button } from "../ui/button"
import { pluralize } from "../../utils/pluralize"
import { getOrdinal } from "../../utils/get-ordinal"
import { getUnits } from "../../utils/services/get-units/get-units"
import { getSessionType } from "../../utils/services/get-session-type/get-session-type"
import { getSessionTypeImage } from "../../utils/services/get-session-type-image"
import { getPrice } from "../../utils/services/get-price"

interface Props {
  service: any
  setUpdateService: any
  getServices: any
  showComponent: any
  setShowArchivedServices: any
  setIsGroupService: any
}

const ServiceCard: React.FC<Props> = ({
  service,
  setUpdateService,
  getServices,
  showComponent,
  setShowArchivedServices,
  setIsGroupService,
}) => {
  const { user } = React.useContext(UserCtx)
  const endpointSlug = user?.coachProfile?.endpoint_slug
  const [showDialogueModal, setShowDialogueModal] =
    React.useState<boolean>(false)
  const { service_details, is_group_service } = service
  const { setPopupNotification, renderSuccess, renderError } =
    React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [loadingToggleChange, setLoadingToggleChange] =
    React.useState<boolean>(false)
  const [isServiceVisible, setIsServiceVisible] = React.useState(
    service_details?.visibility?.profile?.service
  )
  const [showPreview, setShowPreview] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const getSessionCount = () => {
    if (["consultation", "individual"].includes(service_details?.type)) {
      return `${service_details?.session.count} session`
    } else if (["bundle"].includes(service_details?.type)) {
      return pluralize(service_details?.session?.count, "session")
    } else if (["recurring"].includes(service_details?.type)) {
      return (
        <div>
          {pluralize(service_details?.session?.count, "session")}
          <span className="text-darkGray">
            {" "}
            / {`${service_details?.billing?.unit}`}
          </span>
        </div>
      )
    } else {
      return "Not Found"
    }
  }

  const getLengthOrExpiration = () => {
    let expirationString = "No expiration"
    if (
      ["consultation", "individual", "bundle"].includes(service_details?.type)
    ) {
      if (service_details?.expiration?.expires) {
        expirationString = `Expires after ${service_details?.expiration?.time} ${service_details?.expiration?.type}`
      } else {
        expirationString = "No expiration"
      }
    } else if (["recurring"].includes(service_details?.type)) {
      const units =
        service_details?.expiration?.type === "weeks" ? "week" : "month"
      expirationString = `${service_details?.expiration?.time} ${units} program`
    } else {
      expirationString = "Expiration not found"
    }
    return expirationString
  }

  const archiveService = () => {
    setShowDialogueModal(false)
    if (user) {
      startLoading()
      ServiceRequests.archiveService({
        user_id: parseInt(user.user_id),
        stripe_product_id: service.stripe_product_id,
      })
        .then(() => {
          renderSuccess("Your service was archived!")
          getServices()
          showComponent(setShowArchivedServices)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const handleContactSupport = () => {
    window.location.href = "mailto:support@zoee.com"
  }

  const unarchiveService = () => {
    if (user) {
      startLoading()
      ServiceRequests.unarchiveService({
        user_id: parseInt(user.user_id),
        stripe_product_id: service.stripe_product_id,
      })
        .then(() => {
          renderSuccess("Your service was unarchived!")
          getServices()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const showArchiveModal = () => {
    setShowDialogueModal(true)
  }

  const updateServiceProfileVisibility = () => {
    if (user) {
      setLoadingToggleChange(true)
      const updatedVisibility = !isServiceVisible
      setIsServiceVisible(updatedVisibility)
      const serviceObject = {
        ...service_details,
        visibility: {
          ...service_details.visibility,
          profile: {
            ...service_details.visibility.profile,
            service: updatedVisibility,
          },
        },
        image: {
          ...service_details.image,
          base64: "",
          base64extension: "",
        },
      }
      ServiceRequests.updateClientService({
        user_id: parseInt(user.user_id),
        stripe_product_id: service.stripe_product_id,
        stripe_price_id: service.stripe_price_id,
        service: serviceObject,
        group_service_id: service?.group_service_id,
      })
        .then(() => {
          setLoadingToggleChange(false)
          getServices()
          renderSuccess(
            updatedVisibility
              ? `Your service, '${service_details?.title}', is now public!`
              : `Your service, '${service_details?.title}', is now hidden!`
          )
        })
        .catch((ex) => {
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const copyShareLink = () => {
    const base = process.env.REACT_APP_BASE_URL
    const shareLink = `${base}/find-a-coach/${endpointSlug}/services/${service?.service_id}`
    // Copy the text inside the text field
    navigator.clipboard.writeText(shareLink)
    renderSuccess("Copied link to clipboard.")
  }

  const getStartDates = (): { startMessage: string } => {
    const meetingDates = service_details?.group?.meetingDates || []
    const upcomingDates = meetingDates
      .map((date: string, index: number) => {
        const startDate = new Date(date)
        const sessionIndex = index + 1
        return {
          startDate,
          sessionIndex,
          formattedDate: moment(startDate).format("MMM DD [at] h:mm A"),
        }
      })
      .filter((session: any) => session.startDate >= new Date()) 
      .sort((a: any, b: any) => a.startDate - b.startDate) 

    if (upcomingDates.length === 0) {
      return { startMessage: "No upcoming sessions" }
    } else {
      const nextSession = upcomingDates[0] 
      const secondSession = upcomingDates[1] 

      if (nextSession) {
        let startMessage = `${getOrdinal(
          nextSession.sessionIndex
        )} Session Scheduled For ${nextSession.formattedDate}`
        if (secondSession) {
          startMessage = `${getOrdinal(
            secondSession.sessionIndex
          )} Session Scheduled For ${secondSession.formattedDate}`
        }
        return { startMessage }
      }
    }

    return { startMessage: "No upcoming sessions" }
  }

  return (
    <>
      {showDialogueModal && (
        <DeleteDialogueModal
          setShowDialogueModal={setShowDialogueModal}
          confirmCallbackFn={archiveService}
          header="Are you sure?"
          helpText="Once this service is archived, it will be unavailable for members to purchase. It is possible to unarchive the service later. If there are any unused sessions for this Service, they will still be schedulable."
          confirmButtonText="Confirm"
          isWarningVariant={true}
        />
      )}
      <div className="md:w-[508px] w-[374px] main-shadow h-[550px] md:h-[550px] rounded-[16px] relative">
        <div
          className={`w-full h-[120px] rounded-t-[16px] relative ${service_details?.image?.color}`}
        >
          {service_details.image.exists && (
            <img
              src={`https://clientservice-images-${
                process.env.REACT_APP_USER_ENVIRONMENT
              }.s3.amazonaws.com/${
                service.stripe_product_id
              }?t=${new Date().getTime()}`}
              alt="service"
              className="w-full h-full object-cover rounded-t-[16px]"
            />
          )}
          <ServiceCardBannerOverlay service={service} />
        </div>
        <div className="mt-[24px] md:mt-[28px] md:w-[464px] mx-auto flex flex-col px-[24px]">
          {/* TITLE AND PRICE */}
          <div className="w-full flex flex-col px-[16px] py-[12px] flex-1">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-[18px] max-w-[200px]">
                {service_details?.title}
              </h3>
              <h3 className="font-bold text-[22px] text-blurple">
                {getPrice(
                  parseInt(service_details?.billing?.amount),
                  service_details?.symbol
                )}
                <span className="text-graySlate text-base font-normal ml-2">
                  {getUnits(
                    service_details.billing.unit,
                    parseInt(service_details?.billing?.amount),
                    service_details?.type
                  )}
                </span>
              </h3>
            </div>
            {is_group_service && getStartDates().startMessage && (
              <div className="mt-2">
                <Badge variant="secondary">{getStartDates().startMessage}</Badge>
              </div>
            )}
          </div>
          <div className="w-full h-[1px] bg-grayMist mb-[16px]"></div>

          {/* PROGRAM DETAILS */}
          <div className="flex items-center justify-between w-full mb-[16px] text-base font-bold">
            <div className="w-[142px] h-[32px] bg-grayFlash rounded-[8px] flex items-center justify-center">
              Program Details
            </div>
            <div className="flex items-center gap-[8px]">
              {getSessionTypeImage(service_details.type)}
              {getSessionType(service_details.type)}
            </div>
          </div>

          <div className="flex flex-col gap-[12px] mb-4 text-base">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-[12px]">
                {service?.service_details?.details.features.feature1 ? (
                  <>
                    <SvgCheckCircle />
                    {service.service_details.details.features.feature1}
                  </>
                ) : (
                  <>
                    <SvgCheckCircle />
                    {getSessionCount()}
                  </>
                )}
              </div>
              {service_details?.isGroupService && (
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Button
                    variant="destructive"
                    className="h-[30px]"
                    disabled={true}
                  >
                    Cancel
                  </Button>

                  {isHovered && (
                    <a
                      href="mailto:support@zoee.com"
                      className="absolute right-0 mt-1 p-2 bg-lightPurple text-gray-800 text-sm rounded-md shadow-md cursor-pointer opacity-90 w-[250px] h-[100px]"
                      onClick={handleContactSupport}
                    >
                      <span className="ml-0">Under Construction</span>
                      <span
                        role="img"
                        aria-label="Under Construction"
                        className="text-xl"
                      >
                        {" "}
                        {String.fromCodePoint(0x1f6a7)}
                      </span>{" "}
                      <br />
                      To cancel this service, please contact{" "}
                      <b>support@zoee.com</b>
                    </a>
                  )}
                </div>
              )}
            </div>

            <div className="flex items-center gap-[12px]">
              {service?.service_details?.details.features.feature2 ? (
                <>
                  <SvgCheckCircle />
                  {service.service_details.details.features.feature2}
                </>
              ) : (
                <>
                  <SvgCheckCircle />
                  <div>
                    {parseInt(service_details?.session?.duration) / 60} minutes
                    <span className="text-darkGray"> / session</span>
                  </div>
                </>
              )}
            </div>

            <div className="flex items-center gap-[12px]">
              {service?.service_details?.details.features.feature3 ? (
                <>
                  <SvgCheckCircle />
                  {service.service_details.details.features.feature3}
                </>
              ) : (
                <>
                  <SvgCheckCircle />
                  {getLengthOrExpiration()}
                </>
              )}
            </div>
          </div>

          <div className="flex w-full items-center justify-between  mr-auto">
            <p className="font-bold text-base">Service Visibility</p>

            <div className="flex items-center gap-1">
              <Label htmlFor="service-visible">Private</Label>
              {loadingToggleChange ? (
                <Loader />
              ) : (
                <Switch
                  id="service-visible"
                  onCheckedChange={updateServiceProfileVisibility}
                  checked={isServiceVisible}
                />
              )}
              <Label htmlFor="service-visible">Public</Label>
            </div>
          </div>
          <div className="flex items-center justify-between absolute left-[50%] -translate-x-[50%] bottom-[32px] gap-[8px]">
            {["active", "inactive"].includes(service_details?.status) ? (
              <>
                {service_details?.isGroupService &&
                service_details?.status === "active" ? (
                  <Link
                    to={`/find-a-coach/${user.coachProfile.endpoint_slug}/services/${service.service_id}`}
                  >
                    <button className="btn-primary btn-secondary gap-[10px] md:w-[149px]">
                      View
                      <SvgLearn />
                    </button>
                  </Link>
                ) : (
                  <button
                    className="btn-primary btn-secondary gap-[10px] md:w-[149px]"
                    onClick={() => {
                      setUpdateService({
                        serviceType: service_details?.type,
                        show: true,
                        service: service,
                        source: "MyServices",
                      })
                      setIsGroupService(service_details?.isGroupService)
                    }}
                  >
                    Edit
                    <SvgEdit />
                  </button>
                )}
                {service_details?.status === "active" ? (
                  <button
                    className="btn-primary btn-secondary gap-[10px] w-[106px] md:w-[149px]"
                    onClick={copyShareLink}
                  >
                    Share
                    <SvgShare />
                  </button>
                ) : (
                  <></>
                )}
                <button
                  className="btn-primary btn-secondary gap-[10px] w-[106px] md:w-[149px]"
                  onClick={showArchiveModal}
                >
                  Archive
                  <SvgArchive />
                </button>
              </>
            ) : (
              <button
                className="btn-primary btn-secondary gap-[10px] w-[149px]"
                onClick={unarchiveService}
              >
                Unarchive
                <SvgArchive />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceCard
