import React, { FC, useContext, useEffect, useState } from "react"
import StepsLayout from "./StepsLayout"
import { Input } from "../../../ui/input"
import { Button } from "../../../ui/button"
import { InputErrors } from "../page"
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext"
import { StepType } from ".."
import { VerifyPhone } from "../../../../api/app.service"
import { UserCtx } from "../../../../context/userContext"
import Loader from "../../../ui/loader"

interface VerifyPhoneStepProps {
  phoneVerifyCodeError: string
  setInputErrors: (callback: (prev: InputErrors) => InputErrors) => void
  setStep: (step: StepType) => void
  setLoading: (loading: boolean) => void
  loading: boolean
  isIIN: boolean
}

const VerifyPhoneStep: FC<VerifyPhoneStepProps> = ({
  phoneVerifyCodeError,
  setInputErrors,
  setStep,
  setLoading,
  loading,
  isIIN,
}) => {
  const [timer, setTimer] = useState(30)
  const [verificationCode, setVerificationCode] = useState("")
  const [codeResent, setCodeResent] = useState(false)
  const { user } = useContext(UserCtx)
  const { renderError, renderSuccess } = useContext(CommonFunctionCtx)

  const resendCode = () => {
    setLoading(true)
    VerifyPhone.sendPhoneCode({ access_token: user?.access_token })
      .then((data) => {
        data.message === "complete" && setCodeResent(true)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        renderError(err.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const continueToNextStep = () => {
    setLoading(true)
    VerifyPhone.verifyPhoneCode({
      verification_code: verificationCode,
      access_token: user?.access_token,
    })
      .then(() => {
        renderSuccess("Your phone is verified!")
        setStep(StepType.IINCheckout)
        setLoading(false)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <StepsLayout
      isIIN={isIIN}
      step={StepType.VerifyPhone}
    >
      <div className="flex flex-col gap-8 items-center">
        <div className="flex flex-col gap-4">
          <h3 className="text-center text-[32px] font-bold">
            Verify phone number
          </h3>
        </div>
        <form
          className="w-full"
          action="submit"
        >
          <p className="font-bold mb-1">Verification Code</p>
          <div className="flex justify-between gap-2 items-start">
            <div className="w-full">
              <Input
                label="Verification Code"
                placeholder="123456"
                value={verificationCode}
                type="number"
                onChange={(e) => {
                  setVerificationCode(e.target.value)
                  setInputErrors((prev) => ({
                    ...prev,
                    phoneVerifyCodeError: "",
                  }))
                }}
                error={phoneVerifyCodeError}
                onBlur={() => {
                  if (verificationCode.length < 6) {
                    setInputErrors((prev) => ({
                      ...prev,
                      phoneVerifyCodeError: "Invalid code length",
                    }))
                  }
                }}
              />
            </div>
            <div className="flex justify-center items-center">
              <Button
                size="lg"
                className="p-7 font-bold text-base"
                disabled={!verificationCode || Boolean(phoneVerifyCodeError)}
                onClick={(e) => {
                  e.preventDefault()
                  continueToNextStep()
                }}
              >
                {loading ? <Loader /> : "Verify"}
              </Button>
            </div>
          </div>
        </form>
        {timer > 0 ? (
          <p className="text-base">{`Resend code in ${timer} seconds`}</p>
        ) : (
          <p className="text-[#ABB8C3]">
            {codeResent
              ? "We sent a code to your mobile number."
              : "Didn’t receive a code?"}{" "}
            <Button
              variant="link"
              onClick={(e) => {
                e.preventDefault()
                resendCode()
              }}
              className="p-0 font-bold text-blurple text-base underline"
            >
              Resend Code
            </Button>
          </p>
        )}
      </div>
    </StepsLayout>
  )
}

export default VerifyPhoneStep
