import { useState } from "react"
import BBHCDemoThumbnail from "./BBHCDemoThumbnail.png"
import ProductVideoThumbnail from "./ProductVideoThumbnail.png"
const BBHCProductDemo = () => {
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlay = () => {
    setIsPlaying(true)
  }

  return (
    <div
      className="
        md:w-[260px] sm:w-[310px] w-full rounded-[4px] flex justify-center items-center
        mx-auto md:mx-0 relative
      "
    >
      {!isPlaying ? (
        <div
          className="relative w-full h-0"
          style={{ paddingBottom: "56.25%" }}
        >
          <img
            src={ProductVideoThumbnail}
            alt="BBHC Video Thumbnail"
            className="absolute top-0 left-0 w-full h-full object-cover rounded-[4px]"
          />
          <button
            onClick={handlePlay}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-4 shadow-lg hover:bg-gray-100 focus:outline-none"
            aria-label="Play Video"
          ></button>
        </div>
      ) : (
        <div
          style={{
            padding: "56.25% 0 0 0",
            position: "relative",
            width: "100%",
          }}
        >
          <iframe
            src="https://player.vimeo.com/video/1046118056?h=ccea6b88fe&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="BBHC Product Video - January 13th 2025 Cohort"
          />
        </div>
      )}
    </div>
  )
}

export default BBHCProductDemo
