import React, { useRef, useState } from "react"
import useOutsideClick from "../../hooks/useOutsideClick"
import { SvgCheckbox, SvgChevronDown } from "../icons/"

interface Props {
  selectedSort: string
  selectedFilter: string
  setSelectedSort: any
  setSelectedFilter: any
  coaches: any[]
  setCoaches: any
}

const CoachDirectoryFilters: React.FC<Props> = ({
  selectedSort,
  selectedFilter,
  setSelectedSort,
  setSelectedFilter,
  coaches,
  setCoaches,
}) => {
  const [sortByExpanded, setSortByExpanded] = useState<boolean>(false)
  const ref = useRef(null)

  useOutsideClick(ref, () => {
    if (sortByExpanded) {
      setSortByExpanded(false)
    }
  })

  const toggleSortBy = () => {
    setSortByExpanded(!sortByExpanded)
  }

  const safeGetNamePart = (coach: any, part: number) => {
    return part === 0 ? coach.first_name || "" : coach.last_name || ""
  }

  const sortCoachesBy = (sortValue: string) => {
    const sorted = [...coaches]

    if (sortValue === "first") {
      sorted.sort((a: any, b: any) =>
        safeGetNamePart(a, 0).localeCompare(safeGetNamePart(b, 0), undefined, {
          sensitivity: "base",
        })
      )
    } else if (sortValue === "last") {
      sorted.sort((a: any, b: any) =>
        safeGetNamePart(a, 1).localeCompare(safeGetNamePart(b, 1), undefined, {
          sensitivity: "base",
        })
      )
    }


    setCoaches(sorted)
    setSelectedSort(sortValue)
  }

  // const handleSort = (sortValue: string) => {
  //   const sortedItems = sortItems(coaches, sortValue) 
  //   setCoaches(sortedItems) 
  //   setSelectedSort(sortValue) 
  //   if (onSortedCoaches) {
  //     onSortedCoaches(sortedItems) 
  //   }
  // }

  return (
    <div className="flex items-center gap-[32px]">
      <div className="relative" ref={ref}>
        <button
          className="flex items-center gap-[8px] font-bold text-base"
          onClick={toggleSortBy}
        >
          Sort by
          <SvgChevronDown
            className={`${sortByExpanded ? "rotate-[180deg]" : ""}`}
          />
        </button>
        {sortByExpanded && (
          <div
            className="absolute right-0 top-[40px] py-[24px] px-[32px] bg-white
                        rounded-[12px] main-shadow flex flex-col z-[300]"
          >
            <span className="mb-[16px] text-graySlate text-base mr-auto">
              Sort by
            </span>
            <div className="w-[184px] flex flex-col gap-[10px] text-base">
              <button
                className="flex items-center justify-between w-full"
                onClick={() => sortCoachesBy("none")}
              >
                None
                <div
                  className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                flex items-center justify-center
                ${selectedSort === "none" && "bg-blurple"}`}
                >
                  {selectedSort === "none" && <SvgCheckbox />}
                </div>
              </button>
              <button
                className="flex items-center justify-between w-full"
                onClick={() => sortCoachesBy("first")}
              >
                First Name
                <div
                  className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                flex items-center justify-center
                ${selectedSort === "first" && "bg-blurple"}`}
                >
                  {selectedSort === "first" && <SvgCheckbox />}
                </div>
              </button>
              <button
                className="flex items-center justify-between w-full"
                onClick={() => sortCoachesBy("last")}
              >
                Last Name
                <div
                  className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                flex items-center justify-center
                ${selectedSort === "last" && "bg-blurple"}`}
                >
                  {selectedSort === "last" && <SvgCheckbox />}
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CoachDirectoryFilters
