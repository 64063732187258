import React, { FC, useEffect, useContext, useState } from "react"
import BusinessLink from "./BusinessLink"
import {
  ClientPaymentRequests,
  ProfileRequests,
  UserInfo,
  VerifyPhone,
} from "../../api/app.service"
import { useLocation, useNavigate } from "react-router-dom"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import useScrollToTop from "../../hooks/useScrollToTop"
import Loader from "../../components/ui/loader"
import useLoading from "../../hooks/useLoading"
import CodeOfEthicsModal from "../../components/CodeOfEthicsModal"
import OnboardingCallModal from "../../components/OnboardingCallModal"
import { Button } from "../../components/ui/button"
import { NotificationCtx } from "../../context/notificationContext"
import { SubscriptionCtx } from "../../context/subscriptionContext"
import { UserCtx } from "../../context/userContext"
import {
  SvgBilling,
  SvgBusiness,
  SvgCalendar,
  SvgChat,
  SvgCredentials,
  SvgPhone,
  SvgUser3,
} from "../../components/icons/"

interface Props {}

declare global {
  interface Window {
    Calendly: {
      initPopupWidget(config: { url: string }): void
    }
  }
}

const ManageBusiness: FC<Props> = () => {
  const { user } = useContext(UserCtx)
  const location = useLocation()
  const [businessLinks, setBusinessLinks] = useState<any[]>([])
  const [verifyPhoneForm, setVerifyPhoneForm] = useState<boolean>(false)
  const [tryProfileFeature, setTryProfileFeature] = useState<boolean>(false)
  const [profileAccessStatus, setProfileAccessStatus] =
    useState<string>("blocked")
  const [connectAccountData, setConnectAccountData] = useState<any>(null)
  const [sentCode, setSentCode] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { renderError, hideAlert, renderSuccess, updateNextStepsData } =
    useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const { lastWebsocketMessage } = useContext(NotificationCtx)
  const { setUserActiveSubscription } = useContext(SubscriptionCtx)
  const [coachPublicProfileData, setCoachPublicProfileData] = useState<any>()
  const navigate = useNavigate()

  const getCoachPrivateProfile = async () => {
    if (user?.coachProfile) {
      startLoading()
      await ProfileRequests.getCoachPrivateProfile({
        coach_profile_id: parseInt(user.coachProfile.profile_id),
      })
        .then((data) => {
          setCoachPublicProfileData(data)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  const sendVerifyPhone = () => {
    hideAlert()
    if (!sentCode) {
      VerifyPhone.sendPhoneCode({ access_token: user.access_token })
        .then((data) => {
          setSentCode(true)
          renderSuccess("Your email address is verified!")
          data.message === "complete" && setVerifyPhoneForm(true)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  if (["wrong-code", "no-code"].includes(location?.state?.message)) {
    renderError(
      "Email verification failed. Please try again or contact support."
    )
    navigate(location.pathname, { replace: true })
  }

  const getConnectAccount = () => {
    startLoading()
    if (user) {
      ClientPaymentRequests.getConnectAccount({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setConnectAccountData(data.connect_account_data)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  useEffect(() => {
    if (
      lastWebsocketMessage &&
      lastWebsocketMessage.type === "stripe_identity"
    ) {
      getConnectAccount()
    }
  }, [lastWebsocketMessage])

  const populateBusinessLinks = (nextStepsData: any) => {
    const communicationHubTitle =
      user?.organization === "BBHC"
        ? "Client Communication Hub"
        : "My Communication Hub"
    const businessLinksArray = [
      {
        title: "My Calendar",
        text: "Connect your calendar to manage your availability, schedule, and upcoming sessions.",
        link: "/account-settings/calendars",
        linkId: "calendars",
        complete: nextStepsData?.is_calendar_connect,
        step: 1,
        icon: <SvgCalendar />,
      },
      {
        title: "My Services",
        text: "AKA: The heart of your business. This is where you’ll load in the services, programs, and packages you offer clients.",
        link: "/coach/services",
        linkId: "services",
        complete: nextStepsData?.has_created_services,
        step: 2,
        icon: <SvgBusiness />,
      },
      {
        title: "My Profile Page",
        text: "Go ahead, show off a little bit. This is where you’ll create and make updates to your public profile.",
        link: "/coach/profile",
        linkId: "profile",
        complete: profileAccessStatus === "approved" ? 1 : 0,
        step: 3,
        profileAccessStatus: profileAccessStatus,
        icon: <SvgCredentials />,
      },
      {
        title: "Get Paid",
        text: " This is where you’ll sync your bank account and view payments from Zoee to you–the super awesome and successful coach.",
        link: "/coach/client-payments",
        linkId: "payments",
        complete: nextStepsData?.is_bank_connected,
        icon: <SvgBilling />,
      },
      {
        title: "My Contacts",
        text: "Get ready to send some invitations! Once your clients are in Zoee, you'll be off to the races.",
        link: "/coach/contacts",
        linkId: "contacts",
        complete: nextStepsData?.has_sent_invites,
        icon: <SvgUser3 />,
      },
      {
        title: communicationHubTitle,
        text: "This is where the fun starts. Go here to chat with your clients.",
        link: "/coach/inbox",
        linkId: "inbox",
        complete: nextStepsData?.has_sent_chats,
        icon: <SvgChat />,
      },
    ]
    setBusinessLinks(businessLinksArray)
  }

  const getNextStepsValues = async () => {
    await UserInfo.getNextStepsValues({
      user_id: parseInt(user.user_id),
    })
      .then((data) => {
        populateBusinessLinks(data.next_steps_data)
        setProfileAccessStatus(data.next_steps_data.profile_access_status)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const profileLinkClicked = e.currentTarget.id.includes("profile")
    if (profileLinkClicked) {
      if (profileAccessStatus === "blocked") {
        e.preventDefault()
        setTryProfileFeature(true)
      }
    }
  }

  const acceptCodeOfEthics = () => {
    updateNextStepsData(false, "approved").then(() => {
      const url = `https://zoee-downloads-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/CodeOfEthicsZoee.pdf`
      window.open(url, "_blank")
      navigate("/coach/profile", { state: { code_of_ethics_accepted: true } })
    })
  }

  useScrollToTop()

  useEffect(() => {
    getCoachPrivateProfile()
    setUserActiveSubscription()
    getConnectAccount()
  }, [])

  useEffect(() => {
    getNextStepsValues()
    if (location?.state?.verifyPhone === true) {
      sendVerifyPhone()
    } else if (location?.state?.violated_profile_access) {
      renderError(
        `Please accept Code of Ethics to get access to this feature. It's currently in beta.`
      )
    }
  }, [profileAccessStatus])

  useEffect(() => {
    const link = document.createElement("link")
    link.href = "https://assets.calendly.com/assets/external/widget.css"
    link.rel = "stylesheet"
    document.head.appendChild(link)

    const script = document.createElement("script")
    script.src = "https://assets.calendly.com/assets/external/widget.js"
    script.async = true
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(link)
      document.head.removeChild(script)
    }
  }, [])

  const initializeCalendly = () => {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/getstartedwithzoee/group-orientation",
    })
  }

  return (
    <>
      {/* Modals */}
      <OnboardingCallModal />
      {tryProfileFeature && (
        <CodeOfEthicsModal
          setShowModal={setTryProfileFeature}
          confirmCallback={acceptCodeOfEthics}
        />
      )}

      {isModalOpen && (
        <>
          <link
            href="https://assets.calendly.com/assets/external/widget.css"
            rel="stylesheet"
          />
          <script
            src="https://assets.calendly.com/assets/external/widget.js"
            type="text/javascript"
            async
          ></script>
        </>
      )}

      {/* Begin Page */}

      <div className="container max-w-screen-xl">
        <div className="flex flex-col justify-between gap-x-10 md:flex-row">
          <h1 className="mb-2 text-3xl font-bold md:text-5xl">
            Welcome to Zoee, {user?.firstName ? user?.firstName : user?.email}!
          </h1>

          {user?.organization !== "BBHC" && (
            <div className="flex flex-col gap-2 md:items-center md:justify-center">
              <p className="font-bold">Not sure where to start?</p>
              <Button
                variant="outline"
                onClick={() => {
                  setIsModalOpen(!isModalOpen)
                  initializeCalendly()
                }}
              >
                <SvgPhone fill="#4750F5" />
                Schedule Onboarding Call
              </Button>
            </div>
          )}
        </div>
        <h2 className="mb-[8px] mt-6 text-lg font-bold text-black md:mt-0 md:text-xl lg:text-2xl">
          Start building your business in these easy steps:
        </h2>

        <ol className="mt-3 list-inside list-decimal font-bold md:mt-0">
          {/* <li>Verify Identity</li> */}
          <li>Sync Calendar</li>
          <li>Create and publish your first service</li>
        </ol>

        <p className="text-muted-foreground">
          When complete, view your service on "My Profile Page"
        </p>

        <div className="mb-[60px] mt-[45px] grid grid-cols-12 gap-4">
          {!businessLinks.length ? (
            <div className="mx-auto">
              <Loader />
            </div>
          ) : (
            <>
              {businessLinks?.map((link, idx: number) => {
                return (
                  <BusinessLink
                    index={idx}
                    title={link.title}
                    text={link.text}
                    link={link.link}
                    key={idx}
                    complete={link.complete}
                    icon={link.icon}
                    linkId={link.linkId}
                    handleLinkClick={handleLinkClick}
                    profileAccessStatus={profileAccessStatus}
                    connectAccountData={connectAccountData}
                    step={link.step}
                  />
                )
              })}
            </>
          )}
        </div>
      </div>

      <div className="mx-auto flex flex-col items-center px-[24px] pt-[12px] md:w-[1080px] md:px-0 md:pt-0">
        <div className="flex w-full flex-col"></div>
      </div>
    </>
  )
}

export default ManageBusiness
