import React from "react"
import Loader from "../ui/loader"
import PortraitVideoPreview from "./PortraitVideoPreview"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { ProfileRequests } from "../../api/app.service"

interface Props {
  post?: any
  service?: any
}

const CoachVideoManager: React.FC<Props> = ({ post, service }) => {

  const { coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext
  )

  const { renderError } = React.useContext(CommonFunctionCtx)
  const [videos, setVideos] = React.useState<any[]>([])
  const [loadingVideos, setLoadingVideos] = React.useState<boolean>(false)

  const getVideos = () => {
    setLoadingVideos(true)
    ProfileRequests.getCoachProfileVideos({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then((data) => {
        setVideos(data.videos)
        setLoadingVideos(false)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setLoadingVideos(false)
      })
  }

  React.useEffect(() => {
    getVideos()
  }, [coachPublicProfileData])

  return (
    <>
      <div className="w-full mx-auto flex flex-wrap items-start justify-start gap-[16px]">
        <div className="w-full flex items-start justify-start gap-[21px] flex-wrap">
          {loadingVideos ? (
            <Loader />
          ) : (
            <>
              {videos?.slice(0, 4)?.map((video: any, index: number) => (
                <div className="md:w-[256px] h-[350px]" key={index}>
                  <PortraitVideoPreview video={video} />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CoachVideoManager
