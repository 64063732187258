import React from "react";
import { useIntercom } from "react-use-intercom";
import { isLocalStorageAvailable } from "../utils/isLocalStorageAvailable";

export interface User {
  organization: string | null
  IdToken: string;
  RefreshToken: string;
  about: any;
  access_token: string;
  activeProfile: "coach" | "member" | null;
  activeProfileId: any;
  avatar_url: string;
  canRecordSession: boolean;
  coachProfile: any;
  createdAt: any;
  displayName: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  hasAvatar: boolean;
  id_token_expiration: any;
  isCoach: boolean;
  isSubscribed: boolean;
  lastName: string;
  memberProfile: any;
  phone: string;
  phoneVerified: boolean;
  promoCode: string | null;
  region: any;
  showWelcomeVideo: boolean;
  stream_user_id: string;
  stream_user_token: string;
  timezone: any;
  trialExpiration: any;
  userColor: string;
  user_id: string;
  verifyEmailBy: any;
}

interface UserDataInterface {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  clearUser: () => void;
}

const initialUserData = {
  IdToken: "",
  RefreshToken: "",
  about: {},
  access_token: "",
  activeProfile: null,
  activeProfileId: null,
  avatar_url: "",
  canRecordSession: false,
  coachProfile: {},
  createdAt: null,
  displayName: "",
  email: "",
  emailVerified: false,
  firstName: "",
  hasAvatar: false,
  id_token_expiration: null,
  isCoach: false,
  isSubscribed: false,
  lastName: "",
  memberProfile: {},
  phone: "",
  phoneVerified: false,
  promoCode: null,
  region: {},
  showWelcomeVideo: false,
  stream_user_id: "",
  stream_user_token: "",
  timezone: "",
  trialExpiration: null,
  userColor: "",
  user_id: "",
  verifyEmailBy: null,
  organization: null,
};

export const UserCtx = React.createContext<UserDataInterface>({
  user: initialUserData,
  setUser: () => {},
  clearUser: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const UserContextProvider: React.FC<Props> = ({ children }) => {
  const { update, shutdown } = useIntercom();
  let userFromStorage = null;
  if (isLocalStorageAvailable()) {
    userFromStorage = localStorage.getItem("user");
  }

  const userToSet = userFromStorage ? JSON.parse(userFromStorage) : null;
  const [user, setUserState] = React.useState<User>(userToSet);
  
  const setUser = (newUser: any) => {
    setUserState(newUser);
    
    const name = newUser.firstName && newUser.lastName ? newUser.firstName + " " + newUser.lastName : newUser.displayName || newUser.email;
    update({
      email: newUser.email.toLowerCase(),
      userId: newUser.user_id,
      name,
      customAttributes: {
        user_type: newUser.activeProfile,
      },
      avatar: {
        type: "avatar",
        imageUrl: newUser.avatar_url
      }
    })
  }

  const clearUser = () => {
    setUser(initialUserData);
    shutdown();
  };

  return (
    <UserCtx.Provider
      value={{
        user,
        setUser,
        clearUser,
      }}
    >
      {children}
    </UserCtx.Provider>
  );
};
