import React, { Fragment, useContext, useState, useEffect } from "react"
import Loader from "../../ui/loader"
import ServiceCardPreview from "../../services/ServiceCardPreview"
import ServiceUsagePopup from "./ServiceUsagePopup"
import useLoading from "../../../hooks/useLoading"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { Badge } from "../../ui/badge"
import { Button } from "../../ui/button"
import { MeetingRequests, ServiceRequests } from "../../../api/app.service"
import { Link, useNavigate, useParams } from "react-router-dom"
import { UserCtx } from "../../../context/userContext"


interface Props {
  contact: any
  serviceState: string
}

const ServicesPreviewElement: React.FC<Props> = ({ contact, serviceState }) => {
  const [servicesLoading, setServicesLoading] = useState<boolean>(false)
  const [serviceUsage, setServiceUsage] = useState<any>({
    show: false,
    service: {},
  })
  const [selectedState, setSelectedState] = useState<string>("active")
  const { user } = useContext(UserCtx)
  const [services, setServices] = useState<any[]>([])
  const { renderError, renderSuccess } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const navigate = useNavigate()
  const { endpoint_slug } = contact
  const { contact_id } = useParams()

  const handleServiceEnrollClick = (service: any) => {
    const updatedServices = services.map((s) => {
      if (s.service_id === service.service_id) {
        return { ...s, service_status: "active" }
      }
      return s
    })
    setServices(updatedServices)
    setSelectedState("active")
    navigate(`/${user.activeProfile}/contacts/${contact.profile_id}/services`, {
      state: { service },
    })
    renderSuccess("Service invite accepted successfully")
    handleRevokeServiceInvite(service, false)
  }

  const handleScheduleSessionClick = (service: any, contact: any) => {
    navigate(user.isCoach ? "/coach/scheduling" : "/member/scheduling", {
      state: { service, contact },
    })
  }

  const getProfileServiceList = () => {
    let coach_id = 0
    let client_id = 0

    if (!user.isCoach) {
      coach_id = contact.profile_id
      client_id = user.activeProfileId
    } else {
      if (contact.profile_type === "member") {
        coach_id = user.activeProfileId
        client_id = contact.profile_id
      } else {
        if (serviceState === "coach") {
          coach_id = contact_id ? parseInt(contact_id) : 1
          client_id = user.activeProfileId
        } else {
          coach_id = user.activeProfileId
          client_id = contact_id ? parseInt(contact_id) : 1
        }
      }
    }

    if (user) {
      setServicesLoading(true)
      ServiceRequests.getServiceListProfile({
        coach_profile_id: coach_id,
        member_profile_id: client_id,
      })
        .then((data) => {
          setServicesLoading(false)
          setServices(data?.service_list)
        })
        .catch((ex) => {
          setServicesLoading(false)
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  const handleRevokeServiceInvite = (service: any, showMessage = true) => {
    startLoading()
    ServiceRequests.revokeServiceInvite({
      invite_id: parseInt(service.service_invite_id),
    })
      .then((data) => {
        if (showMessage) {
          renderSuccess(data.message)
        }
        getProfileServiceList()
        stopLoading()
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const handleDenyServiceInvite = (service: any) => {
    startLoading()
    ServiceRequests.denyServiceInvite({
      invite_id: service.service_invite_id,
      coach_user_id: contact.user_id,
      client_user_id: +user.user_id,
    })
      .then((data) => {
        renderSuccess(data.message)
        getProfileServiceList()
        stopLoading()
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  useEffect(() => {
    getProfileServiceList()
  }, [])

  const selectPathname = () => {
    interface displayObjType {
      services: string
      link: string
      invite: string
      button_text: string
    }

    let displayObj: displayObjType = {
      services: "",
      link: "",
      invite: "",
      button_text: "",
    }

    if (user.activeProfile === "coach" && contact.profile_type === "member") {
      displayObj.services = "Sent Invites"
      displayObj.link = `/${user.activeProfile}/contacts/${contact_id}/services`
      displayObj.invite = "Invite to Service"
      displayObj.button_text = "Revoke Invite"
      return displayObj
    } else if (
      user.activeProfile === "member" &&
      contact.profile_type === "coach"
    ) {
      displayObj.services = "Pending"
      displayObj.link = `/find-a-coach/${endpoint_slug}`
      displayObj.invite = "See Available Services"
      displayObj.button_text = "Accept Invite"
      return displayObj
    } else if (
      user.activeProfile === "coach" &&
      contact.profile_type === "coach"
    ) {
      if (serviceState === "client") {
        displayObj.services = "Sent Invites"
        displayObj.link = `/${user.activeProfile}/contacts/${contact_id}/services`
        displayObj.invite = "Invite to Service"
        displayObj.button_text = "Revoke Invite"
        return displayObj
      } else if (serviceState === "coach") {
        displayObj.services = "Pending"
        displayObj.link = `/find-a-coach/${endpoint_slug}`
        displayObj.invite = "See Available Services"
        displayObj.button_text = "Accept Invite"
        return displayObj
      }
    }
  }

  return (
    <>
      {serviceUsage.show && (
        <ServiceUsagePopup
          setServiceUsage={setServiceUsage}
          serviceUsage={serviceUsage}
        />
      )}
      <div className="w-full main-shadow rounded-[16px] px-[16px] py-[24px] md:p-[40px] relative mb-[32px]">
        <div className="w-full flex flex-col md:flex-row items-center justify-between mb-[20px]">
          <h2 className="hidden md:block font-semibold text-[18px]">
            {serviceState === "coach"
              ? "Services I enrolled into:"
              : serviceState === "client"
              ? `Services I provide:`
              : "My Services"}
            {/* My Services */}
          </h2>
          <div className="md:hidden w-full flex items-center justify-between mb-[24px]">
            <h2 className="font-bold text-[18px]">Services</h2>
            <Button asChild className="text-md">
              <Link
                to={{
                  pathname: selectPathname()?.link,
                  search:
                    user.activeProfile !== "coach" ? "?services=true" : "",
                }}
                className="h-[32px] min-w-fit"
              >
                {selectPathname()?.invite}
              </Link>
            </Button>
          </div>
          <div className="flex items-center gap-[24px] mb-[26px] md:mb-0">
            <div className="flex items-center p-[4px] bg-hover rounded-[12px]">
              <button
                className={`flex items-center justify-center font-bold text-base rounded-[8px]
                                ${
                                  selectedState === "active"
                                    ? "text-mainBlack bg-white attendee-toggle-shadow"
                                    : "text-gray"
                                } py-[4px] px-[24px]`}
                onClick={() => setSelectedState("active")}
              >
                Active
              </button>
              <button
                className={`flex items-center justify-center font-bold text-base rounded-[8px]
                                ${
                                  selectedState === "past"
                                    ? "text-mainBlack bg-white attendee-toggle-shadow"
                                    : "text-gray"
                                } py-[4px] px-[24px]`}
                onClick={() => setSelectedState("past")}
              >
                Past
              </button>
              <button
                className={`flex items-center justify-center font-bold text-base rounded-[8px]
                                ${
                                  selectedState === "pendingInvite"
                                    ? "text-mainBlack bg-white attendee-toggle-shadow"
                                    : "text-gray"
                                } py-[4px] px-[24px]`}
                onClick={() => setSelectedState("pendingInvite")}
              >
                {selectPathname()?.services}

                {services.filter((s: any) => s.service_status === "pending")
                  .length > 0 ? (
                  <Badge variant="alert" className="ml-1">
                    {
                      services.filter(
                        (s: any) => s.service_status === "pending"
                      ).length
                    }
                  </Badge>
                ) : (
                  <></>
                )}
              </button>
            </div>
            <Link
              to={{
                pathname: selectPathname()?.link,
                search:
                  contact.profile_type === "coach" ? "?services=true" : "",
              }}
              className="hidden md:flex btn-primary btn-blue bg-blurple rounded-md h-[32px] min-w-fit"
            >
              {selectPathname()?.invite}
            </Link>
          </div>
        </div>
        {servicesLoading ? (
          <div
            className="w-full h-[464px] flex items-center justify-center flex-col
                        rounded-[16px] border border-grayMist bg-[#f8f8f8] gap-[10px]"
          >
            <span className="mb-2">Loading services...</span>
            <Loader />
          </div>
        ) : (
          <div className="flex flex-wrap justify-center md:justify-between w-full gap-[24px]">
            {selectedState === "active" && (
              <>
                {services.map((service: any) => {
                  if (service.service_status === "active") {
                    return (
                      <Fragment key={service.service_usage_id}>
                        {/* MOBILE */}
                        <div className="md:hidden">
                          <ServiceCardPreview
                            contact={contact}
                            service={service}
                            buttonText={
                              service.is_group_service
                                ? "Meetings are Scheduled"
                                : "Schedule Session"
                            }
                            buttonCallback={() =>
                              serviceState === "coach" || serviceState === ""
                                ? handleScheduleSessionClick(service, contact)
                                : handleScheduleSessionClick(
                                    service,
                                    contact.original_contact
                                  )
                            }
                            secondaryButtonCallback={null}
                            showUsageSummary={true}
                            setServiceUsage={setServiceUsage}
                          />
                        </div>
                        {/* DESKTOP */}
                        <div className="hidden md:block w-[48%]">
                          <ServiceCardPreview
                            contact={contact}
                            service={service}
                            buttonText={
                              service.is_group_service
                                ? "Meetings are Scheduled"
                                : "Schedule Session"
                            }
                            buttonCallback={() =>
                              serviceState === "coach" || serviceState === ""
                                ? handleScheduleSessionClick(service, contact)
                                : handleScheduleSessionClick(
                                    service,
                                    contact.original_contact
                                  )
                            }
                            secondaryButtonCallback={null}
                            showUsageSummary={true}
                            setServiceUsage={setServiceUsage}
                            markCompletedButton={
                              <>
                                {(user.activeProfile === "coach" &&
                                  contact.profile_type === "coach" &&
                                  serviceState === "client" &&
                                  service.available_session_count > 0) ||
                                (user.activeProfile === "coach" &&
                                  contact.profile_type === "member" &&
                                  service.available_session_count > 0) ? (
                                  <Button
                                    variant="ghost"
                                    size="lg"
                                    onClick={() => {
                                      MeetingRequests.markSessionCompleted({
                                        user_id: contact?.user_id,
                                        profile_id: contact?.profile_id,
                                        coach_user_id: parseInt(user?.user_id),
                                        meeting_details: {
                                          service_usage_id:
                                            service?.service_usage_id,
                                          participants: [
                                            {
                                              user_id: user?.user_id,
                                              profile_id:
                                                user?.coachProfile.profile_id,
                                              email: user?.email,
                                            },
                                            {
                                              user_id: contact?.user_id,
                                              profile_id: contact?.profile_id,
                                              email: contact?.email,
                                            },
                                          ],
                                        },
                                      })
                                        .then(() => {
                                          getProfileServiceList()
                                          renderSuccess(
                                            "Session marked as completed"
                                          )
                                        })
                                        .catch((ex) => {
                                          renderError(
                                              ex.response.data.message
                                          )
                                        })
                                    }}
                                  >
                                    Mark Completed
                                  </Button>
                                ) : null}
                              </>
                            }
                          />
                        </div>
                      </Fragment>
                    )
                  } else {
                    return <></>
                  }
                })}
                {services.filter(
                  (service) => service.service_status === "active"
                ).length === 0 && (
                  <div
                    className="w-full h-[496px] flex items-center justify-center 
                                        rounded-[16px] border border-grayMist bg-[#f8f8f8]"
                  >
                    No Active Services
                  </div>
                )}
              </>
            )}
            {selectedState === "past" && (
              <>
                {services.map((service: any, idx: number) => {
                  if (service.service_status === "past") {
                    return (
                      <Fragment key={service.service_usage_id}>
                        {/* MOBILE */}
                        <div className="md:hidden">
                          <ServiceCardPreview
                            contact={contact}
                            service={service}
                            buttonText="Schedule Session"
                            buttonCallback={() =>
                              serviceState === "coach" || serviceState === ""
                                ? handleScheduleSessionClick(service, contact)
                                : handleScheduleSessionClick(
                                    service,
                                    contact.original_contact
                                  )
                            }
                            secondaryButtonCallback={null}
                            showUsageSummary={true}
                            setServiceUsage={setServiceUsage}
                          />
                        </div>

                        {/* DESKTOP */}
                        <div className="hidden md:block w-[48%]">
                          <ServiceCardPreview
                            contact={contact}
                            service={service}
                            buttonText="Schedule Session"
                            buttonCallback={() =>
                              serviceState === "coach" || serviceState === ""
                                ? handleScheduleSessionClick(service, contact)
                                : handleScheduleSessionClick(
                                    service,
                                    contact.original_contact
                                  )
                            }
                            secondaryButtonCallback={null}
                            showUsageSummary={true}
                            setServiceUsage={setServiceUsage}
                          />
                        </div>
                      </Fragment>
                    )
                  } else {
                    return <></>
                  }
                })}
                {services.filter((service) => service.service_status === "past")
                  .length === 0 && (
                  <div
                    className="w-full h-[496px] flex items-center justify-center 
                                        rounded-[16px] border border-grayMist bg-[#f8f8f8]"
                  >
                    No Past Services
                  </div>
                )}
              </>
            )}
            {selectedState === "pendingInvite" && (
              <>
                {services
                  .filter((service) => service.service_status === "pending")
                  .map((service: any, idx: number) => (
                    <Fragment key={service.service_usage_id}>
                      {/* MOBILE */}
                      <div className="md:hidden">
                        <ServiceCardPreview
                          contact={contact}
                          service={service}
                          buttonText={selectPathname()?.button_text ?? "Invite"}
                          buttonCallback={() => {
                            if (
                              selectPathname()?.button_text === "Revoke Invite"
                            ) {
                              handleRevokeServiceInvite(service)
                            } else {
                              handleServiceEnrollClick(service)
                            }
                          }}
                          secondaryButtonCallback={() =>
                            handleDenyServiceInvite(service)
                          }
                        />
                      </div>
                      {/* DESKTOP */}
                      <div className="hidden md:block">
                        <ServiceCardPreview
                          contact={contact}
                          service={service}
                          buttonText={selectPathname()?.button_text ?? "Invite"}
                          buttonCallback={() => {
                            if (
                              selectPathname()?.button_text === "Revoke Invite"
                            ) {
                              handleRevokeServiceInvite(service)
                            } else {
                              handleServiceEnrollClick(service)
                            }
                          }}
                          secondaryButtonCallback={() =>
                            handleDenyServiceInvite(service)
                          }
                        />
                      </div>
                    </Fragment>
                  ))}
                {services.filter(
                  (service) => service.service_status === "pending"
                ).length === 0 && (
                  <div
                    className="w-full h-[496px] flex items-center justify-center 
                            rounded-[16px] border border-grayMist bg-[#f8f8f8]"
                  >
                    No Pending Services
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ServicesPreviewElement
