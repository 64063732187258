import React, { useEffect } from "react"

const BBHCWaitlist: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://form.jotform.com/jsform/243266205590152";
    script.type = "text/javascript";
    script.async = true;
  
    const container = document.getElementById("jotform-content");
    if (container) {
      container.appendChild(script);
    }
    const handleIframeLoad = () => {
      const frame = document.getElementById("243266205590152");
      if (frame) {
        frame.style.height = "710px";
      }
    };
    window.addEventListener("load", handleIframeLoad);
    
    return () => {
      window.removeEventListener("load", handleIframeLoad);
    };
  }, []);

  return (
    <div
      id="jotform-container"
      className="w-full max-w-lg flex flex-col justify-center items-center rounded-lg p-4 bg-[#5865F2] shadow-md"
    >
      <div
        id="jotform-content"
        className="w-full h-fit p-6 bg-white rounded-md"
      ></div>
    </div>
  )
}

export default BBHCWaitlist
