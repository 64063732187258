import React, { useRef } from "react"
import ProductImage4 from "./images/image 54.png"
import { Button } from "../ui/button"
import { useNavigate } from "react-router-dom"

const IINPriceBlock: React.FC<{
  pathname: string
  jotformRef: React.RefObject<HTMLDivElement>
}> = ({ pathname, jotformRef }) => {
  const navigate = useNavigate()

  const handleCreateAccount = () => {
    navigate("/iin-registration", { state: { from: pathname } })
  }
  const scrollToJotform = () => {
    if (jotformRef?.current) {
      jotformRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  return (
    <div className="flex flex-row gap-10 lg:flex-row items-center lg:justify-between w-full relative md:py-[96px] py-[52px] md:pr-[144px] pr-4 sm:pr-8 max-w-screen-xl mx-auto">
      <div className="hidden md:block border-l-[48px] border-l-[#37d3a1]">
        <img
          src={ProductImage4}
          alt="Woman looking at laptop screen and thinking"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Right Section - Content */}
      <div className="border-l-[48px] border-l-[#37d3a1] md:border-l-0 flex flex-col lg:ml-0 justify-center gap-8 lg:w-1/2 w-full h-auto">
        {/* Title and Price */}
        <div className="flex flex-col gap-5">
          <h2 className="text-black text-[48px] font-bold leading-tight">
            Gain exclusive access to the Business Blueprint for Health Coaches
            for
          </h2>
          <p className="md:text-[32px] text-[24px]">
            <span className="text-[#4750f5] font-bold">ONLY $695</span>{" "}
            <span className="font-medium">(value $1,200)</span>
          </p>
        </div>

        {/* Button */}
        <div className="w-full flex justify-start">
          {/* Register Button */}
          {/* <Button
            variant="default"
            onClick={handleCreateAccount}
            className="px-4 py-2 bg-[#4750F5] text-white items-center gap-2 rounded-lg"
          >
            REGISTER NOW
          </Button> */}
          {/* Waitlist Button */}
          <Button
            variant="default"
            onClick={scrollToJotform}
            className="px-4 py-2 bg-[#4750F5] text-white items-center gap-2 rounded-lg"
          >
            JOIN WAITLIST
          </Button>
        </div>
      </div>
    </div>
  )
}

export default IINPriceBlock
