import React from "react"

interface ProfileInterface {
  coachPublicProfileData: any
  setCoachPublicProfileData: any
  selectedTile: string
  setSelectedTile: any
  hasProfileVideo: boolean
  setHasProfileVideo: any
  getCoachPublicProfile: any
  services: any
  setServices: any
  getServices: any
  largeVideo: any
  setLargeVideo: any
  loadingServices: boolean
  setLoadingServices: any
  displayBlogPost: any
  setDisplayBlogPost: any
  showServiceDetails: any
  setShowServiceDetails: any
  isOwner: boolean
  newsletter: {
    enabled: boolean
    title: string
    subheading: string
  }
  addCoachNewsletterSubscribers: (email: string) => void
}

export const CoachPublicProfileContext = React.createContext<ProfileInterface>({
  coachPublicProfileData: {},
  setCoachPublicProfileData: {},
  selectedTile: "about",
  setSelectedTile: {},
  hasProfileVideo: false,
  setHasProfileVideo: {},
  getCoachPublicProfile: {},
  services: {},
  setServices: {},
  getServices: {},
  largeVideo: {},
  setLargeVideo: {},
  loadingServices: false,
  setLoadingServices: {},
  displayBlogPost: {},
  setDisplayBlogPost: {},
  showServiceDetails: {},
  setShowServiceDetails: {},
  isOwner: false,
  newsletter: { enabled: false, title: "", subheading: "" },
  addCoachNewsletterSubscribers: () => {},
})
