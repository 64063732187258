import {
  selectIsConnectedToRoom,
  selectPermissions,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import React from "react";
import { ContactRequests, UserInfo } from "../api/app.service";
import { isLocalStorageAvailable } from "../utils/isLocalStorageAvailable";
import { createSearchParams, useNavigate } from "react-router-dom";
import { UserCtx } from "./userContext";
import useLoading from "../hooks/useLoading";
import { StandardAlert } from "../components/alerts/StandardAlert";

export interface GetUserReturnType {
  about: any;
  access_token: string;
  activeProfile: string;
  activeProfileId: number;
  avatar_url: string;
  canRecordSession: boolean;
  coachProfile: {
    profile_id: number;
    profile_data: any;
  };
  createdAt: string;
  displayName: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  hasAvatar: boolean;
  id_token: string;
  id_token_expiration: string;
  isCoach: boolean;
  isSubscribed: boolean;
  lastName: string;
  memberProfile: {
    profile_id: number;
    profile_data: any;
  };
  phone: string;
  phoneVerified: boolean;
  promoCode: string;
  region: {
    label: string;
    value: string;
  };
  showWelcomeVideo: boolean;
  timezone: {
    value: string;
  };
  trialExpiration: string;
  userColor: string;
  user_id: string;
  verifyEmailBy: string;
}

interface CommonFunctionInterface {
  getUser: (options?: {
    successRedirectPathname?: string;
    successRedirectSearchParams?: string;
    preRedirectActions?: string;
    redirectToDashboard?: boolean;
  }) => any;
  renderError: (message: string, duration?: number) => void;
  renderSuccess: (message: string, duration?: number) => void;
  hideAlert: () => void;
  popupNotification: {
    message?: string;
    title: string;
    show: boolean;
    callback?: any;
  };
  setPopupNotification: React.Dispatch<
    React.SetStateAction<{
      message?: string;
      title: string;
      show: boolean;
      callback?: any;
    }>
  >;
  updateNextStepsData: (
    created_chat?: boolean,
    profile_access_status?: string,
  ) => any;
  setShowMobileNavBottom: React.Dispatch<React.SetStateAction<boolean>>;
  showMobileNavBottom: boolean;
  endActiveSession: () => any;
  initializeCreateAccountModal: any;
  setInitializeCreateAccountModal: React.Dispatch<React.SetStateAction<any>>;
  showAuthModal: boolean;
  setShowAuthModal: React.Dispatch<React.SetStateAction<boolean>>;
  previewImgUrl: string;
  setPreviewImgUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const CommonFunctionCtx = React.createContext<CommonFunctionInterface>(
  {} as CommonFunctionInterface,
);

interface Props {
  children: React.ReactNode;
}

export const CommonFunctionProvider: React.FC<Props> = ({ children }) => {
  const { user, setUser } = React.useContext(UserCtx);
  const history = useNavigate();
  const navigate = useNavigate();
  const { startLoading, stopLoading } = useLoading();

  const hmsActions = useHMSActions();
  const permissions = useHMSStore(selectPermissions);
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  const [previewImgUrl, setPreviewImgUrl] = React.useState<string>("");
  const [showAuthModal, setShowAuthModal] = React.useState<boolean>(false);
  const [initializeCreateAccountModal, setInitializeCreateAccountModal] = React
    .useState<any>(null);

  const [showMobileNavBottom, setShowMobileNavBottom] = React.useState<boolean>(
    true,
  );
  const [error, setError] = React.useState<{ message: string; show: boolean }>({
    message: "",
    show: false,
  });
  const renderError = (message: string, duration: number = 5000) => {
    setError({ message, show: true });
    if (duration > 0) {
      setTimeout(() => hideAlert(), duration);
    }
  };

  const [success, setSuccess] = React.useState<{
    message: string;
    show: boolean;
  }>({
    message: "",
    show: false,
  });
  const renderSuccess = (message: string, duration: number = 5000) => {
    setSuccess({ message: message, show: true });
    if (duration > 0) {
      setTimeout(() => hideAlert(), duration);
    }
  };

  const hideAlert = () => {
    setError({ message: "", show: false });
    setSuccess({ message: "", show: false });
  };

  const [popupNotification, setPopupNotification] = React.useState<{
    show: boolean;
    title: string;
    message?: string;
  }>({
    show: false,
    title: "",
    message: "",
  });

  const endActiveSession = async () => {
    if (isConnected) {
      if (permissions?.endRoom) {
        const lock = true;
        const reason = "Host ended the session";
        await hmsActions.endRoom(lock, reason).catch((error: any) => {
          console.log(error);
        });
      } else {
        await hmsActions.leave().catch((error) => {
          console.log(error);
        });
      }
    }
  };

  const getUser = async (options?: any): Promise<
    GetUserReturnType | null
  > => {
    if (!isLocalStorageAvailable()) {
      renderError("Local storage is not available");
      return null;
    }

    try {
      const userString = localStorage.getItem("user");

      if (!userString) {
        return null;
      }

      const user = JSON.parse(userString);

      const userData = await UserInfo.getUser({
        user_id: parseInt(user.user_id),
      });
      // const activeProfile = options?.preRedirectActions?.forceMemberLogin
      //   ? "member"
      //   : userData.user_data.is_coach
      //   ? "coach"
      //   : "member";
      // const activeProfileId = activeProfile === "coach"
      //   ? userData.user_data.coach_profile?.profile_id
      //   : userData.user_data.member_profile.profile_id;

      const userObject = {
        ...user,
        about: userData.user_data.about,
        activeProfile: userData.user_data.active_profile,
        activeProfileId: userData.user_data.active_profile_id,
        avatar_url: userData.user_data.avatar_url,
        canRecordSession: userData.user_data.can_record_session,
        coachProfile: userData.user_data.coach_profile,
        createdAt: userData.user_data.created_at,
        displayName: userData.user_data.display_name,
        email: userData.user_data.email,
        emailVerified: userData.user_data.email_verified,
        firstName: userData.user_data.first_name,
        hasAvatar: userData.user_data.has_avatar,
        isCoach: userData.user_data.is_coach,
        isSubscribed: userData.user_data.is_subscribed,
        lastName: userData.user_data.last_name,
        memberProfile: userData.user_data.member_profile,
        phone: userData.user_data.phone_number,
        phoneVerified: userData.user_data.phone_number_verified,
        promoCode: userData.user_data.promo_code,
        region: userData.user_data.region,
        showWelcomeVideo: userData.user_data.show_welcome_video,
        timezone: userData.user_data.timezone,
        trialExpiration: userData.user_data.trial_expiration,
        userColor: userData.user_data.user_color,
        verifyEmailBy: userData.user_data.verify_email_by,
        organization: userData.user_data.organization
      };

      setUser(userObject);
      localStorage.setItem("user", JSON.stringify(userObject));

      if (options) {
        if (options?.preRedirectActions?.createConnection) {
          connectAndRedirect(options, userData.user_data);
        } else if (
          options?.successRedirectPathname &&
          options?.successRedirectSearchParams
        ) {
          history({
            pathname: options.successRedirectPathname,
            search: createSearchParams(
              options.successRedirectSearchParams,
            ).toString(),
          });
        } else if (options?.successRedirectPathname) {
          history({
            pathname: options.successRedirectPathname,
            search: createSearchParams(
              options.successRedirectSearchParams,
            ).toString(),
          });
        } else if (options?.redirectToDashboard === true) {
          userObject.isCoach ? history("/coach") : history("/member");
        }
      }

      return userObject;
    } catch (error: any) {
      console.log(error);
      renderError(error.response.data.message);
    }

    return null;
  };

  const connectAndRedirect = (options: any, userData: any) => {
    startLoading("Connecting you to the coach");
    ContactRequests.createContactConnection({
      sender_profile_id: options?.preRedirectActions?.createConnection
        ?.coachProfileId,
      receiver_profile_id: userData?.isCoach ? userData?.coach_profile?.profile_id : userData?.member_profile?.profile_id,
      user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
      status: "connected",
    })
      .then(() => {
        stopLoading();
        navigate(options.successRedirectPathname, {
          state: {
            actions: {
              scheduleMeeting: options?.preRedirectActions?.scheduleMeeting ||
                null,
            },
          },
        });
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        stopLoading();
      });
  };

  const updateNextStepsData = async (
    created_chat?: boolean,
    profile_access_status?: string,
  ) => {
    if (user) {
      await UserInfo.updateNextStepsValues({
        user_id: parseInt(user.user_id),
        active_profile_id: user.isCoach ? parseInt(user.coachProfile.profile_id) : parseInt(user.memberProfile.profile_id),
        created_chat: created_chat ? true : false,
        profile_access_status: profile_access_status || "",
      });
    }
  };

  return (
    <CommonFunctionCtx.Provider
      value={{
        getUser,
        previewImgUrl,
        setPreviewImgUrl,
        setShowAuthModal,
        showAuthModal,
        initializeCreateAccountModal,
        setInitializeCreateAccountModal,
        renderError,
        renderSuccess,
        hideAlert,
        updateNextStepsData,
        popupNotification,
        setPopupNotification,
        endActiveSession,
        showMobileNavBottom,
        setShowMobileNavBottom,
      }}
    >
      <StandardAlert {...error} variant="error" />
      <StandardAlert {...success} variant="success" />
      {children}
    </CommonFunctionCtx.Provider>
  );
};
