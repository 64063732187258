import React, { useState, useContext, useEffect } from "react"
import NewsletterSettingsModal from "../NewsletterSettingsModal"
import { SvgEdit } from "../../icons"
import { UserCtx } from "../../../context/userContext"
import { PublicProfileRequests } from "../../../api/public.service"

export default function NewsletterManage() {
  const { user } = useContext(UserCtx)
  const [isModalOpen, setModalOpen] = useState(false)
  const [newsletterEnabled, setNewsletterEnabled] = useState(false)
  const [newsletterTitle, setNewsletterTitle] = useState(
    "Sign up for my weekly newsletter below!"
  )
  const [newsletterSubheading, setNewsletterSubheading] = useState(
    "Collect emails directly from your Zoee page."
  )
  const itemStyle = {
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "0 0 20px 5px rgba(150, 150, 150, 0.2)",
  }
  useEffect(() => {
    const fetchNewsletterSettings = async () => {
      try {
        const response =
          user?.coachProfile?.endpoint_slug &&
          (await PublicProfileRequests.getCoachPublicProfile({
            endpoint_slug: user?.coachProfile?.endpoint_slug || "0",
          }))

        const profileData = response?.coach_public_profile
        if (profileData) {
          setNewsletterEnabled(profileData.newsletter_opt_in_enabled ?? false)
          setNewsletterTitle(
            profileData.newsletter_title ??
              "Sign up for my weekly newsletter below!"
          )
          setNewsletterSubheading(
            profileData.newsletter_subheading ??
              "Collect emails directly from your Zoee page."
          )
        }
      } catch (error) {
        console.error("Failed to fetch newsletter settings:", error)
      }
    }

    if (user?.coachProfile?.profile_id) {
      fetchNewsletterSettings()
    }
  }, [user])

  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const handleSaveSettings = (
    title: string,
    subheading: string,
    enabled: boolean
  ) => {
    setNewsletterTitle(title)
    setNewsletterSubheading(subheading)
    setNewsletterEnabled(enabled)
    setModalOpen(false)
  }

  return (
    <div
      className="newsletter-container w-full py-8 px-6 md:px-[240px] flex flex-col justify-center items-center gap-6 relative"
      style={{ ...itemStyle }}
    >
      {" "}
      {newsletterEnabled ? (
        <>
          <div className="w-full flex items-center relative">
            <div className="w-full flex flex-col items-center">
              <h1 className="text-black text-xl md:text-2xl font-bold font-['Beausite Classic'] text-center">
                {newsletterTitle}
              </h1>
              {/* <p className="text-[#3a434a] text-sm md:text-base font-normal font-['Beausite Classic'] text-center mt-2">
                {newsletterSubheading}
              </p> */}
            </div>
            <button
              onClick={handleOpenModal}
              className="newsletter-edit-btn absolute top-0 right-0 mt-2 mr-2 md:mt-0 md:mr-0"
            >
              <SvgEdit />
            </button>
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-4">
            <input
              type="email"
              placeholder="Enter your email"
              className="flex-grow h-10 md:h-12 px-4 bg-white rounded-lg border border-[#abb8c3] text-[#7d878f] text-sm md:text-base font-normal font-['Beausite Classic'] leading-normal"
            />
            <button className="w-full md:w-auto px-4 py-2 md:px-6 md:py-3 bg-[#4750f5] rounded-lg text-white text-sm md:text-base font-bold font-['Beausite Classic'] leading-normal">
              Submit
            </button>
          </div>
              <p className="text-[#3a434a] text-sm md:text-base font-normal font-['Beausite Classic'] text-center mt-2">
                {newsletterSubheading}
              </p>
        </>
      ) : (
        <>
          <div className="w-full flex items-center justify-center gap-2 relative">
            <h1 className="text-black text-xl md:text-2xl font-bold font-['Beausite Classic']">
              Add email opt-in form
            </h1>
            <button
              onClick={handleOpenModal}
              className="newsletter-edit-btn ml-2"
            >
              <SvgEdit />
            </button>
          </div>
          <p className="text-[#3a434a] text-sm md:text-base font-normal font-['Beausite Classic'] text-center mx-auto mt-4 leading-normal">
            You can collect emails directly from your Zoee page.
          </p>
        </>
      )}
      {isModalOpen && (
        <NewsletterSettingsModal
          onClose={handleCloseModal}
          onSave={handleSaveSettings}
          defaultTitle={newsletterTitle}
          defaultSubheading={newsletterSubheading}
          defaultEnabled={newsletterEnabled}
          userId={parseInt(user?.user_id || "0", 10)}
        />
      )}
    </div>
  )
}
