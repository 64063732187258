import React, { useState } from "react";
import CloseModal from "../CloseModal";
import CsvInstructions from "./CsvInstructions";
import DragDropCsvArea from "../DragDropCsvArea";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { Button } from "../ui/button";
import { UserCtx } from "../../context/userContext";
import { Input } from "../ui/input";
import {
  SvgCheckbox,
  SvgCheckboxOff,
  SvgWarning,
  SvgPlus,
  SvgQuestionCircle,
} from "../icons/";
import { Link } from "react-router-dom";

interface Props {
  setShowInviteModal: any;
  invites: any;
  setInvites: any;
  setShowConfirmInviteModal: any;
  isReferralInviteVariant?: boolean;
  invitedContacts?: any;
}

const ContactInvite: React.FC<Props> = ({
  setShowInviteModal,
  invites = [],
  setInvites,
  setShowConfirmInviteModal,
  isReferralInviteVariant,
  invitedContacts = []
}) => {
  const { renderError } = React.useContext(CommonFunctionCtx);
  const { user } = React.useContext(UserCtx);
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [csvEmails, setCsvEmails] = React.useState<Array<string> | undefined>();
  const [showInstructions, setShowInstructions] =
    React.useState<boolean>(false);

  const coachValidateForm = () => {
    let valid = true;
    const newErrors = invites.map(() => ({ email: "" }));
    const sentInvites = invitedContacts.map((invCont: any) => invCont.contact_name)
    invites.forEach((invite: any) => {
      const mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      if (!invite.email.match(mailformat)) {
        newErrors.email = "Please add a valid email address";
        valid = false;
      } else if (invite.email === user.email) {
        newErrors.email = "Cannot use your own email address";
        valid = false;
      } else if (sentInvites.includes(invite.email) && isChecked) {
        newErrors.email = "This email has already been used in contact request";
        valid = false;
      }
    });

    setErrors(newErrors);
    return valid && isChecked;
  };

  const memberValidateForm = () => {
    let valid = false;

    invites.forEach((invite: any) => {
      const mailformat = /^\w+([.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
      if (invite.email.match(mailformat) && invite.email !== user.email) {
        valid = true;
      }
    });

    return valid;
  };

  const coachInviteConfirmation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (coachValidateForm()) {
      setShowInviteModal(false);
      setShowConfirmInviteModal(true);
    } else if (!isChecked) {
      renderError("Please Authorize before continuing.");
    }
  };

  const updateInviteArray = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const newState = [...invites];
    newState[index][e.target.name] = e.target.value;
    setInvites(newState);

    if (e.target.name === "email") {
      setErrors((prev) => ({ ...prev, email: "" }));
    }
  };

  const toggleCheckbox = () => {
    setIsChecked((prev) => !prev);
  };

  const closeAndReset = () => {
    setInvites([{ email: "" }]);
    setShowInviteModal(false);
  };
  const memberInviteConfirmation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (memberValidateForm()) {
      setShowInviteModal(false);
      setShowConfirmInviteModal(true);
    } else {
      renderError("Please add at least one valid email address");
    }
  };

  const addContactRow = () => {
    setInvites([...invites, { email: "" }]);
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-[801] flex items-center justify-center pb-[220px] md:pb-[30px] zoee-backdrop-filter">
      <div className="pt-[22px] pb-[30px] md:py-[32px] px-[24px] sm:px-[48px] w-[351px] md:w-[551px] sm:w-auto bg-white main-shadow rounded-[16px] flex flex-col items-center relative">
        {user.activeProfile === "coach" ? (
          <>
            <div className="text-[12px] mb-[24px] w-full text-graySlate">
              This {isReferralInviteVariant ? "coach" : "contact"} will be
              entered into the Zoee system. Since this is a manual addition,
              there won’t be any recorded opt-in IP address or date, so please
              ensure you have their consent beforehand. Please take a moment to
              review our{" "}
              <Link
                to="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Terms
              </Link>{" "}
              &{" "}
              <Link
                to="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Privacy Policy
              </Link>
              .
            </div>
            <form onSubmit={coachInviteConfirmation} noValidate>
              <div className="flex flex-col w-full">
                {invites.map((invite: any, index: any) => (
                  <div className="w-full md:w-[404px] mb-2" key={index}>
                    <div>
                      <Input
                        label={`Email`}
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={(e) => updateInviteArray(e, index)}
                        id={`email${index}`}
                      />
                      {errors.email && (
                        <div
                          style={{ color: "red" }}
                          className="flex items-center text-red-500 text-sm"
                        >
                          <SvgWarning className="w-[18px] h-[18px] mr-1" />{" "}
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="flex items-center text-[12px] mb-[24px] w-[100%] text-graySlate mt-4"
                onClick={toggleCheckbox}
              >
                {isChecked ? (
                  <SvgCheckbox className="w-[18px] h-[18px]" />
                ) : (
                  <SvgCheckboxOff
                    className="w-[18px] h-[18px]"
                    fill="lightgray"
                  />
                )}
                <span className="ml-2">
                  This individual has authorized me to create a Zoee account for
                  them.
                </span>
              </div>
              <div className="flex items-center gap-2 mt-4 justify-end">
                <Button variant="outline" type="button" onClick={closeAndReset}>
                  Cancel
                </Button>
                <Button type="submit">Next</Button>
              </div>
            </form>
          </>
        ) : (
          <>
            {showInstructions && (
              <CsvInstructions setShowInstructions={setShowInstructions} />
            )}          <>
            <div className="text-[12px] mb-[24px] w-full text-graySlate">
              This {isReferralInviteVariant ? "coach" : "contact"} will be
              entered into the Zoee system. Since this is a manual addition,
              there won’t be any recorded opt-in IP address or date, so please
              ensure you have their consent beforehand. Please take a moment to
              review our{" "}
              <Link
                to="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Terms
              </Link>{" "}
              &{" "}
              <Link
                to="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Privacy Policy
              </Link>
              .
            </div>
            <form onSubmit={memberInviteConfirmation} noValidate>
              <div className="flex flex-col w-full">
                {invites.map((invite: any, index: any) => (
                  <div className="w-full md:w-[404px] mb-2" key={index}>
                    <div>
                      <Input
                        label={`Email`}
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={(e) => updateInviteArray(e, index)}
                        id={`email${index}`}
                      />
                      {errors.email && (
                        <div
                          style={{ color: "red" }}
                          className="flex items-center text-red-500 text-sm"
                        >
                          <SvgWarning className="w-[18px] h-[18px] mr-1" />{" "}
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="flex items-center text-[12px] mb-[24px] w-[100%] text-graySlate mt-4"
                onClick={toggleCheckbox}
              >
                {isChecked ? (
                  <SvgCheckbox className="w-[18px] h-[18px]" />
                ) : (
                  <SvgCheckboxOff
                    className="w-[18px] h-[18px]"
                    fill="lightgray"
                  />
                )}
                <span className="ml-2">
                  This individual has authorized me to create a Zoee account for
                  them.
                </span>
              </div>
              <div className="flex items-center gap-2 mt-4 justify-end">
                <Button variant="outline" type="button" onClick={closeAndReset}>
                  Cancel
                </Button>
                <Button type="submit">Next</Button>
              </div>
            </form>
          </>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactInvite;
