import React from "react"
import moment from "moment"
import { Badge } from "../ui/badge"
import { getOrdinal } from "../../utils/get-ordinal"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@radix-ui/react-popover"
import { ServiceTypes } from "../../data/service-types"

interface Props {
  service: any
}

const ServiceCardBannerOverlay: React.FC<Props> = ({ service }) => {
  const { service_details, is_group_service } = service

  // const getEnrollmentStatus = (): {
  //   message: string;
  //   background: "success" | "destructive";
  // } => {
  //   const enrollByDate = new Date(service_details?.group?.enrollByDate);

  //   return {
  //     message:
  //       enrollByDate >= new Date()
  //         ? `Enrollment Open Until ${moment(enrollByDate).format("MMM DD")}`
  //         : "Enrollment Closed",
  //     background: enrollByDate >= new Date() ? "success" : "destructive",
  //   };
  // };

  const getStartDate = (): { startMessage: string } => {
    const meetingDates = service_details.group.meetingDates || []
    const sessionCount = meetingDates.length
  
    if (sessionCount === 0) {
      return { startMessage: "No sessions available" }
    } else if (sessionCount === 1) {
      const date = meetingDates[0]
      const isPast = new Date(date) < new Date()
      const variant = isPast ? "destructive" : "success"
      const formattedDate = moment(date).format("MMM DD [at] h:mm A")
      const sessionStatus = isPast ? "was" : "Scheduled for"
      return {
        startMessage: `Session ${sessionStatus} ${formattedDate}`,
      }
    } else {
      const messages = meetingDates.map((date: any, index: any) => {
        const isPast = new Date(date) < new Date()
        const variant = isPast ? "destructive" : "success"
        const sessionNumber = index + 1
        const sessionLabel = getOrdinal(sessionNumber)
        const formattedDate = moment(date).format("MMM DD [at] h:mm A")
        const sessionStatus = isPast ? "was" : "Scheduled for"
        return {
          startMessage: `${sessionLabel} Session ${sessionStatus} ${formattedDate}`,
        }
      })
  
      return messages[0] 
    }
  }

  const { type } = service_details
  const { Icon, title, description } = ServiceTypes[type]
  // const { background, message } = getEnrollmentStatus();
  return (
    <Popover>
      <div className="flex flex-col items-start gap-2 absolute top-12 left-4">
        <PopoverTrigger
          onClick={(e) => e.stopPropagation()}
          className="flex flex-col items-start"
        >
          <Badge variant="secondary" className="mr-2 mb-2 absolute -top-8">
            <Icon className="mr-1" />
            {title}
          </Badge>
          {/* {is_group_service && (
            <>
              <Badge variant={background} className="mb-2">
                {message}
              </Badge>
            </>
          )} */}
          {is_group_service && getStartDate().startMessage && (
            <div className="-top-1">
              <Badge variant="success">{getStartDate().startMessage}</Badge>
            </div>
          )}
        </PopoverTrigger>
      </div>
      <PopoverContent
        side="bottom"
        className="flex flex-col items-center rounded-xl gap-3 bg-[#F8F8F8] p-3"
      >
        <Icon className="h-6 w-6 mx-auto" />
        <h3 className="font-bold text-[16px] md:text-[18px] md:mb-[8px] text-center">
          {title}
        </h3>
        <p className="md:text-center w-[310px] text-base text-graySlate mb-[16px] md:mb-[24px]">
          {description}
        </p>
      </PopoverContent>
    </Popover>
  )
}

export default ServiceCardBannerOverlay
