import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet-async"
import { Button } from "../ui/button"
import IINLanding from "./IINLanding"
import IINQuestions from "./IINQuestions"
import IINThriving from "./IINThriving"
import IINMeetZoee from "./IINMeetZoee"
import IINTestimonials from "./IINTestimonials"
import IINWhy from "./IINWhy"
import IINPriceBlock from "./IINPriceBlock"
import IINMessage from "./IINMessage"
import IINFAQs from "./IINFAQs"
import IINJotform from "./IINJotform"
import { SvgZoeeLogo } from "../icons"
import { SvgIINLogo } from "./IINLogo"
import IINProgram from "./IINProgram"
import { useLocation, useNavigate } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import IINDemo from "./IINDemo"

const IIN: React.FC = () => {
  const { shutdown } = useIntercom()
  useEffect(() => {
    shutdown()
  }, [shutdown])

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  })

  const pathname = useLocation().pathname.split("/")[1]
  const navigate = useNavigate()

  // useEffect(() => {
  //   // Midnight EST January 10th, 2025
  //   const targetDate = new Date("2025-01-11T05:00:00Z").getTime()
  //   const updateCountdown = () => {
  //     const now = new Date().getTime()
  //     const timeLeft = targetDate - now

  //     if (timeLeft > 0) {
  //       const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
  //       const hours = Math.floor(
  //         (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //       )
  //       const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
  //       setCountdown({ days, hours, minutes })
  //     } else {
  //       setCountdown({ days: 0, hours: 0, minutes: 0 })
  //     }
  //   }
  //   updateCountdown()
  //   const interval = setInterval(updateCountdown, 60000)
  //   return () => clearInterval(interval)
  // }, [])

  const jotformRef = useRef<HTMLDivElement | null>(null)

  const scrollToJotform = () => {
    if (jotformRef.current) {
      jotformRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  return (
    <div className="w-full overflow-x-hidden">
      {/* Header Section */}
      <div className="w-full px-4 py-3  flex justify-center items-center ">
        <div className="w-full max-w-6xl px-6 py-3  rounded-[20px] shadow-lg flex justify-between items-center">
          {/* Logos */}
          <div className="-ml-2 sm:ml-4 flex items-center">
            <SvgIINLogo className="h-4 -mr-6 sm:h-6 md:w-28 sm:w-16 sm:-ml-6 sm:-mr-0 xsm:w-24 xsm:-ml-10" />
            <div className="px-4 sm:h-px rotate-90 border-t border-black"></div>
            <SvgZoeeLogo className="h-4 sm:h-6 md:w-auto md:mr-6 sm:-ml-0 -ml-6" />{" "}
          </div>
          {/* //  sm:h-4 sm:-ml-6 xsm:-ml-6 xsm:h-4 */}

          <div className="hidden sm:flex sm:items-center sm:gap-2 md:gap-4">
            THE REGISTRATION IS CLOSED
          </div>
          
          {/* UNCOMMENT FOR NEXT COHORT */}
          {/* <div className="hidden sm:flex sm:items-center sm:gap-2 md:gap-4">
            {[
              { value: countdown.days, label: "Days" },
              { value: countdown.hours, label: "Hours" },
              { value: countdown.minutes, label: "Minutes" },
            ].map(({ value, label }, index) => (
              <div key={index} className="flex items-center gap-2">
                <div className="text-black text-xl font-bold">{value}</div>
                <div className="text-black text-xl font-normal">{label}</div>
              </div>
            ))}
            <div className="hidden lg:inline-block text-black text-xl font-normal">
              LIMITED TIME OFFER
            </div>
          </div> */}

          {/* Register Button */}
          {/* <Button
            variant="default"
            className="bg-[#4750f5] text-white px-6 py-2 rounded-lg text-base font-bold"
            onClick={() =>
              navigate("/iin-registration", { state: { from: pathname } })
            }
          >
            <span className="hidden sm:block">REGISTER NOW</span>
            <span className="block sm:hidden">REGISTER</span>
          </Button> */}
          {/* Waitlist Button */}
          <Button
            variant="default"
            className="bg-[#4750f5] text-white px-6 py-2 rounded-lg text-base font-bold"
            onClick={scrollToJotform}
          >
            <span className="hidden sm:block">JOIN WAITLIST</span>
            <span className="block sm:hidden">WAITLIST</span>
          </Button>
        </div>
      </div>

      {/* Content Sections */}
      <IINLanding pathname={pathname} jotformRef={jotformRef} />
      <IINQuestions pathname={pathname} jotformRef={jotformRef} />
      <IINProgram pathname={pathname} jotformRef={jotformRef} />
      <IINThriving />
      <IINMeetZoee pathname={pathname} jotformRef={jotformRef} />
      <IINTestimonials />
      <IINDemo />
      <IINWhy />
      <IINPriceBlock pathname={pathname} jotformRef={jotformRef} />
      <IINMessage />
      <IINFAQs />
      <div ref={jotformRef}>
        <IINJotform />
      </div>
    </div>
  )
}

export default IIN
