import React, { useState, useEffect, useContext } from "react"
import useLoading from "../../hooks/useLoading"
import ConnectCalendarWarningCardModal from "../ConnectCalendarWarningCardModal"
import GroupVsIndividualSelector from "../services/GroupVsIndividualSelector"
import SyncPrompt from "../services/SyncPrompt"
import { ClientPaymentRequests } from "../../api/app.service"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { getTextColor } from "../../utils/getTextColor"
import { SvgWarningSign } from "../icons/"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

interface Props {
  userHasCalendar: any
}

export default function CreateConsultationBanner({ userHasCalendar }: Props) {
  const navigate = useNavigate()
  const { coachPublicProfileData } = useContext(CoachPrivateProfileContext)
  const [showCalendarNotice, setShowCalendarNotice] = useState<any>(false)
  const [groupVsIndividualSelector, setGroupVsIndividualSelector] =
    useState<boolean>(false)
  const [showSyncPrompt, setShowSyncPrompt] = useState(false)
  const [paypalStatus, setPaypalStatus] = useState<any>(null)
  const { startLoading, stopLoading } = useLoading()
  const { renderError } = useContext(CommonFunctionCtx)
  const { user } = useContext(UserCtx)
  const [syncPrompt, setSyncPrompt] = useState<any>({
    show: false,
    service: {},
  })
  const [service, setService] = useState(null)
  const [connectAccountData, setConnectAccountData] = useState<any>(null)
  const [isGroupService, setIsGroupService] = useState<boolean>(false)
  const [createService, setCreateService] = useState<boolean>(false)

  const getPaypalOnboardingStatus = async () => {
    if (user) {
      startLoading()
      try {
        const onboardingStatus =
          await ClientPaymentRequests.getPaypalOnboardingStatus({
            seller_payer_id: "",
            seller_user_id: parseInt(user?.user_id),
          })

        setPaypalStatus(onboardingStatus)
      } catch (error) {
        renderError(
          "An error occurred trying to fetch your PayPal onboarding status"
        )
      } finally {
        stopLoading()
      }
    }
  }

  useEffect(() => {
    getPaypalOnboardingStatus()
  }, [])

  const showGroupVsIndividualSelector = () => {
    if (!userHasCalendar) {
      setShowCalendarNotice(true)
    } else {
      navigate(`/coach/services`, {
        state: { serviceType: "consultation" },
      })
    }
  }

  return (
    <>
      {showCalendarNotice && (
        <ConnectCalendarWarningCardModal
          setShowCalendarNotice={setShowCalendarNotice}
        />
      )}
      {showSyncPrompt && (
        <SyncPrompt
          setSyncPrompt={setShowSyncPrompt}
          connectAccountData={connectAccountData}
          service={service}
        />
      )}
      {groupVsIndividualSelector && (
        <GroupVsIndividualSelector
          setGroupVsIndividualSelector={setGroupVsIndividualSelector}
          setIsGroupService={setIsGroupService}
          setCreateService={setCreateService}
        />
      )}
      <div
        className="w-full px-[48px] py-[32px] rounded-[10px]
            border border-1 border-gray flex flex-col lg:flex-row items-center justify-between"
      >
        <div className="flex flex-col items-start gap-2">
          <div className="flex">
            <span>
              <SvgWarningSign />
            </span>
            <h5 className="font-bold text-[18px] ml-2">
              You haven't created a consultation service
            </h5>
          </div>
          <div className="flex flex-col ">
            <p className="text-base ml-8">
              Create a consultation service to adjust your profile visibility
              and allow new clients to book an initial session.
            </p>
          </div>
        </div>
        {/* TODO: USE NAVIGATE BELOW TO SHOW AVAILABILITY TAB WHEN LANDING ON /COACH/SCHEDULING */}

        <button
          onClick={showGroupVsIndividualSelector}
          className={`h-[50px] px-[16px] py-[6px] rounded-md w-full lg:w-auto mt-[20px] md:m-0 shadow-outline hover:opacity-90`}
          style={{
            backgroundColor: `${coachPublicProfileData?.profile_information?.profileColor}`,
            color: `${getTextColor(
              coachPublicProfileData?.profile_information?.profileColor
            )}`,
          }}
        >
          Create Consultation
        </button>
      </div>
    </>
  )
}
