import React, { FC, useContext, useEffect } from "react"
import StepsLayout from "../StepsLayout"
import { Button } from "../../../../ui/button"
import { Input } from "../../../../ui/input"
import PhoneInput from "react-phone-input-2"
import RegisterPassReqs from "./components/RegisterPassReqs"
import { InputErrors } from "../../page"
import { validateFirstAndLastName } from "../../../../auth/register-form/name-form"
import { CommonFunctionCtx } from "../../../../../context/commonFunctionContext"
import Loader from "../../../../ui/loader"
import { useSearchParams } from "react-router-dom"
import { AccountType } from "../../../../auth/register-form/context"
import { StepType } from "../.."

interface CreateAccountStepProps {
  phone: string | null
  setPhone: (phone: string) => void
  password: string
  setPassword: (password: string) => void
  setFirstName: (name: string) => void
  firstName: string
  setLastName: (name: string) => void
  lastName: string
  setEmail: (email: string) => void
  accountType: AccountType
  setInputErrors: (callback: (prev: InputErrors) => InputErrors) => void
  validPassword: boolean
  firstNameError: string
  lastNameError: string
  phoneError: boolean
  setStep: (step: StepType.VerifyPhone) => void
  step: StepType
  createAccount: (organization?: string | undefined) => Promise<void>
  setLoading: (loading: boolean) => void
  loading: boolean
}

const CreateAccountStep: FC<CreateAccountStepProps> = ({
  phone,
  setPhone,
  password,
  setPassword,
  setInputErrors,
  validPassword,
  firstName,
  lastName,
  setEmail,
  firstNameError,
  lastNameError,
  setFirstName,
  setLastName,
  phoneError,
  setStep,
  step,
  loading,
  setLoading,
  createAccount,
}) => {
  const { renderError } = useContext(CommonFunctionCtx)
  const [searchParams] = useSearchParams()
  const isIIN = searchParams.get("flow") === "IIN"
  const searchEmail = isIIN ? "verification" : "verification"
  const userEmailFromParams = searchParams.get(searchEmail) || ""

  const validatePhone = () => {
    if (phone && phone.length < 11) {
      renderError("Phone number must be at least 10 characters long.")
      setInputErrors((prev) => ({ ...prev, phoneError: true }))
    }
  }

  const btnDisabled =
    !firstName ||
    Boolean(firstNameError) ||
    !lastName ||
    Boolean(lastNameError) ||
    !phone ||
    phoneError ||
    !validPassword

  const continueToCreateAccount = () => {
    setLoading(true)
    try {
      createAccount("BBHC")
    } catch (error: any) {
      renderError("Creating Account Faild")
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setInputErrors((prev) => ({ ...prev, phoneError: false }))

    if (!validateFirstAndLastName(firstName).success) {
      setInputErrors((prev) => ({
        ...prev,
        firstNameError: "Invalid Input",
      }))
    }

    if (!validateFirstAndLastName(lastName).success) {
      setInputErrors((prev) => ({
        ...prev,
        lastNameError: "Invalid Input",
      }))
    }
  }, [firstName, lastName, phone])

  useEffect(() => {
    if (userEmailFromParams) {
      setEmail(userEmailFromParams)
    }
  }, [])

  return (
    <StepsLayout isIIN={isIIN} step={step}>
      <div className="">
        <h3 className="text-[32px] font-bold text-center">
          Create your Zoee Account
        </h3>
        <form
          action="submit"
          className="mt-8 mb-2 flex flex-col gap-4 md:gap-6"
        >
          <div className="w-full grid gap-4 md:grid-cols-2 md:gap-2">
            <Input
              label="First Name"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value)
                setInputErrors((prev) => ({
                  ...prev,
                  firstNameError: "",
                }))
              }}
              error={firstNameError}
            />
            <Input
              label="Last Name"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value)
                setInputErrors((prev) => ({
                  ...prev,
                  lastNameError: "",
                }))
              }}
              error={lastNameError}
            />
          </div>
          <PhoneInput
            placeholder="Enter your mobile number"
            country="us"
            onChange={setPhone}
            value={phone}
            containerClass={`default-input w-full`}
            inputClass={`w-full h-full rounded-lg focus:py-0 phone-input`}
            inputProps={{ enableSearch: true, id: `phone` }}
            onBlur={validatePhone}
          ></PhoneInput>
          <Input
            type="password"
            label="Password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <RegisterPassReqs
            password={password}
            setValidPassword={setInputErrors}
          />
          <Button
            type="submit"
            disabled={btnDisabled}
            onClick={(e) => {
              e.preventDefault()
              continueToCreateAccount()
            }}
          >
            {loading ? <Loader /> : "Continue"}
          </Button>
        </form>
        <p className="text-sm text-[#7D878F] text-center">
          You must have a valid phone number to securely use Zoee’s services.
        </p>
      </div>
    </StepsLayout>
  )
}

export default CreateAccountStep
