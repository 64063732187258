import React from "react"
import CoachWelcomeVideo from "../../pages/manage-business/CoachWelcomeVideo"
import BBHCFoundersThumbnail from "./BBHCFoundersThumbnail.png"
import BBHCFoundersVideo from "./BBHCFoundersVideo"

const IINMessage: React.FC = () => {
  return (
    <div
      className="flex flex-col lg:flex-row items-center justify-between w-full h-auto xlg:px-0 py-[56px] md:py-[96px] gap-16 mx-auto md:px-[144px] px-4 sm:px-8 max-w-screen-xl mx-auto"
      style={{ fontFamily: "BeausiteClassic" }}
    >
      {/* Left Section - Title and Text */}
      <div className="flex flex-col gap-6 lg:w-2/3 text-left">
        <h2
          className="text-black text-[48px] font-bold leading-tight"
          style={{
            lineHeight: "1.2",
          }}
        >
          <span className="block md:inline">A message</span>{" "}
          <span className="block md:inline">from Our</span>{" "}
          <span className="block">CEOs</span>
        </h2>

        <p
          className="text-black font-normal"
          style={{
            fontSize: "22px",
            lineHeight: "26.4px",
          }}
        >
          Hear from Nichole Lowe, founder and CEO of Zoee, and <br />
          Nora McCaffrey, MSEd, CEO of the Institute for <br />
          Integrative Nutrition, on why this program is the premier <br />
          solution for Health Coaches seeking the education and <br />
          technology to build or scale their online coaching <br />
          businesses with efficiency and ease.
        </p>
      </div>

      {/* Right Section - Video */}
      <div
        className="w-full lg:w-1/2 aspect-w-16 aspect-h-9 bg-[#d9d9d9] rounded-lg overflow-hidden max-w-[600px] max-h-[400px]"
      >
        <BBHCFoundersVideo />
      </div>
    </div>
  )
}

export default IINMessage
