import React, { useState } from "react"
import BBHCFoundersThumbnail from "./images/BBHCFoundersThumbnail.png"

const BBHCFoundersVideo: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlay = () => {
    setIsPlaying(true)
  }

  return (
    <div className="relative w-full max-w-4xl mx-auto">
      {/* Display the thumbnail with play button initially */}
      {!isPlaying && (
        <div
          className="relative cursor-pointer"
          onClick={handlePlay}
          style={{
            position: "relative",
            paddingTop: "56.25%", // 16:9 aspect ratio
          }}
        >
          <img
            src={BBHCFoundersThumbnail}
            alt="BBHC Founders Thumbnail"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          {/* Play button overlay */}
          <div className="absolute inset-0 flex items-center justify-center">
            <button
              className="bg-black bg-opacity-75 rounded-full p-4 flex items-center justify-center"
              style={{ width: "70px", height: "70px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 24 24"
                width="40"
                height="40"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Display the Vimeo video when playing */}
      {isPlaying && (
        <div style={{ paddingTop: "56.25%", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/1036134937?h=bea2744d84&badge=0&autopause=0&player_id=0&app_id=58479"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
            title="Zoee project 1 v0.2"
          ></iframe>
        </div>
      )}
    </div>
  )
}

export default BBHCFoundersVideo
