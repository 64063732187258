import React, { useContext, useState } from "react"
import { CoachPublicProfileContext } from "../../../pages/manage-business/profile/context/coachPublicProfileContext"

const NewsletterOptInForm: React.FC = () => {
  const { newsletter, addCoachNewsletterSubscribers } = useContext(
    CoachPublicProfileContext
  )
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)
  
  const itemStyle = {
    backgroundColor: "#f2f5f7",
    borderRadius: "20px",
    boxShadow: "0 0 20px 5px rgba(150, 150, 150, 0.2)",
    height: "210px"
  }

  const handleSubmit = () => {
    if (!email.trim()) {
      alert("Please enter a valid email.")
      return
    }
    addCoachNewsletterSubscribers(email)
    setEmail("")
    setSubmitted(true)
  }

  if (submitted) {
    return (
      <div className="w-full flex items-center relative" style={{...itemStyle}}>
        <div className="w-full flex flex-col items-center">
          <h2 className="text-black text-xl md:text-2xl font-bold font-['Beausite Classic'] text-center">
            Thanks! You’re in.
            Stay up to date on my exclusive content in your inbox.
          </h2>
        </div>
      </div>
    )
  }

  return (
    <div className="newsletter-container w-full py-8 px-6 md:px-[240px] bg-[#f2f5f7] rounded-[20px] flex flex-col justify-center items-center gap-6 relative">
      {/* Title */}
      <div className="w-full flex items-center relative">
        <div className="w-full flex flex-col items-center">
          <h1 className="text-black text-xl md:text-2xl font-bold font-['Beausite Classic'] text-center">
            {newsletter.title || "Sign up for my weekly newsletter below!"}
          </h1>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center gap-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="flex-grow h-10 md:h-12 px-4 bg-white rounded-lg border border-[#abb8c3] text-[#7d878f] text-sm md:text-base font-normal font-['Beausite Classic'] leading-normal"
        />
        <button
          className="w-full md:w-auto px-4 py-2 md:px-6 md:py-3 bg-[#4750f5] rounded-lg text-white text-sm md:text-base font-bold font-['Beausite Classic'] leading-normal"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      <div>
      <p className="text-[#3a434a] text-sm md:text-base font-normal font-['Beausite Classic'] text-center mt-2">
              {newsletter.subheading}
            </p>
      </div>
    </div>
  )
}

export default NewsletterOptInForm
