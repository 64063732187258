import { useContext, useState, useEffect } from "react"
import RegistrationPage from "./page"
import {
  CommonFunctionCtx,
  GetUserReturnType,
} from "../../../context/commonFunctionContext"
import { OnSuccessfulAuthCallback } from "../../auth/context"
import { RegisterFormProvider } from "../../auth/register-form/context"
import { SubscriptionContext } from "../../subscription"
import { useLocation } from "react-router-dom"
import { useSearchParams } from "react-router-dom"
import { VerifyPhone } from "../../../api/app.service"

export enum StepType {
  Email = "email",
  VerifyEmail = "verify-email",
  VerifyPhone = "verify-phone",
  CreateAccount = "create-account",
  IINCheckout = "iin-checkout",
  EnrollmentFinal = "enrollment-final",
}

interface LocationState {
  from?: string
}

const WrappedIINRegistrationPage = () => {
  const [step, setStep] = useState<StepType>(StepType.Email)
  const [loading, setLoading] = useState(false)
  const { renderError } = useContext(CommonFunctionCtx)

  const location = useLocation()
  const [searchParams] = useSearchParams()
  const locationState = location.state as LocationState
  const returnUser = searchParams.get("returnUser") || false
  const [isIIN, setIsIIN] = useState(true)


  const [appliedDiscount, setAppliedDiscount] = useState<any>({
    amount: 0,
    promoCode: "",
  })
  const { createSetupIntent } = useContext(SubscriptionContext)
  const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false)

  const onSuccess: OnSuccessfulAuthCallback = (user: GetUserReturnType) => {
    if (user) {
      VerifyPhone.sendPhoneCode({ access_token: user.access_token })
        .then((data) => {
          setLoading(false)
          data.message === "complete" && setStep(StepType.VerifyPhone)
        })
        .catch((err) => {
          console.error(err)
          renderError(err.response.data.message)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if (returnUser) {
      setStep(StepType.IINCheckout)
    }
  }, [returnUser])

  return (
    <RegisterFormProvider
      defaultEmail=""
      defaultAccountType="coach"
      defaultFirstName=""
      defaultLastName=""
      defaultPassword=""
      defaultPhone=""
      onSuccess={onSuccess}
    >
      <RegistrationPage
        isIIN={isIIN}
        step={step}
        setStep={setStep}
        loading={loading}
        setLoading={setLoading}
        appliedDiscount={appliedDiscount}
        createSetupIntent={createSetupIntent}
        isTermsAgreed={isTermsAgreed}
        setIsTermsAgreed={setIsTermsAgreed}
        selectedPlanFromChild=""
      />
    </RegisterFormProvider>
  )
}

export default WrappedIINRegistrationPage
export * from "./page"